import { css, styled } from 'styled-components';
import { tokens } from '@gnist/themes/tokens.css.js';

export type Step = string;

interface ProgressBarProps {
    readonly steps: Step[];
    readonly activeStep: Step;
}

const TextWrapper = styled.div`
    display: flex;
    padding-bottom: ${tokens.spacing.xxs};
`;

const ProgressCount = styled.p`
    font-size: ${tokens.typeface.size.s};
    padding-right: ${tokens.spacing.xxs};
    min-width: ${tokens.size.s};
`;

const Bar = styled.div`
    background-color: ${tokens.palette.neutral['40']};
    height: 6px;
    border-radius: 3px;
    display: flex;
`;

const BarFilled = styled(Bar)<{ $width: number }>`
    ${(props) => css`
        background-color: ${tokens.palette.primary['10']};
        width: ${props.$width}%;
        border-radius: ${props.$width === 100 ? '3px' : '3px 0 0 3px'};
        transition: width linear 250ms;
    `}
`;

export const ProgressBar = ({ steps, activeStep }: ProgressBarProps) => {
    const progressCountLabel = `Steg ${steps.indexOf(activeStep) + 1} av ${
        steps.length
    }`;
    const progressFilledWidth =
        (100 / steps.length) * (steps.indexOf(activeStep) + 1);

    return (
        <>
            <TextWrapper>
                <ProgressCount aria-label={progressCountLabel}>
                    {progressCountLabel}
                </ProgressCount>
            </TextWrapper>
            <Bar>
                <BarFilled $width={progressFilledWidth} />
            </Bar>
        </>
    );
};
