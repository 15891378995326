import i18next from 'i18next';
import { css, styled } from 'styled-components';
import { CarModel } from '@/types/CarModel';
import { breakpointDesktop } from '@gnist/design-system/utilities/layout';
import { numberFormat } from '@/utils/numberFormat';
import { InfoCard } from '@gnist/design-system';
import { SelfDeclarationLink } from '@/views/my-page/components/SelfDeclarationLink';
import { tokens } from '@gnist/themes/tokens.css.js';

const Card = styled(InfoCard)`
    display: flex;
    flex-flow: wrap;
    row-gap: ${tokens.spacing.xxs};
    border-color: ${tokens.color.outline};
`;

const Item = styled.div`
    display: flex;
    flex-direction: column;
    align-self: center;
    line-height: ${tokens.typeface['line-height']};
    width: 100%;

    @media screen and (min-width: ${breakpointDesktop}) {
        width: 50%;
    }
`;

const Label = styled.p`
    font-size: ${tokens.typeface.size.s};
`;

const Value = styled.p<{ $disabledColor: boolean }>`
    ${(props) =>
        props.$disabledColor &&
        css`
            color: ${tokens.color['on-disabled']};
        `}
    font-weight: 500;
`;

interface CarInfoProps {
    readonly car: CarModel;
    readonly comment: string;
}

export const CarInfo = ({ car, comment }: CarInfoProps) => {
    const carItems = [
        { label: 'regNo', value: car.registrationNumber },
        {
            label: 'model',
            value: car.brand
                ? `${car.brand} ${car.model}`
                : i18next.t('unknown'),
        },
        { label: 'mileage', value: `${numberFormat(car.mileage)} km` },
        { label: 'otherInfo', value: comment },
    ];

    return (
        <Card transparent>
            {carItems.map(
                (item) =>
                    item.value && (
                        <Item key={item.label}>
                            <Label>{i18next.t(item.label)}</Label>
                            <Value
                                $disabledColor={
                                    !car.brand && item.label === 'model'
                                }
                            >
                                {item.value}
                            </Value>
                        </Item>
                    ),
            )}
            <Item>
                <SelfDeclarationLink />
            </Item>
        </Card>
    );
};
