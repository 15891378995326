import { Heading1Text, Icon, PrimaryButton } from '@gnist/design-system';
import i18next from 'i18next';
import { styled } from 'styled-components';
import { NewCarBox } from '@/components/NewCarBox';
import { useNavigate } from 'react-router';
import { PATH_MY_PAGE } from '@/utils/url/routes';
import { NewCarBoxWrapper } from '../ValuationRequestView';
import { useEffect } from 'react';
import { datadogRum } from '@datadog/browser-rum';
import { useGetUrlParams } from '../utils/useGetUrlParams';
import { ResponseAlertMessage } from '@/components/ResponseAlertMessage';
import { tokens } from '@gnist/themes/tokens.css.js';

interface ConfirmedPageContentProps {
    readonly isCustomerLoginAvailable: boolean;
    readonly isPriceEstimateAvailable: boolean;
}

const TitleWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const TitleIcon = styled(Icon)`
    margin-left: ${tokens.spacing.xs};

    svg {
        height: ${tokens.spacing.m};
        width: ${tokens.spacing.m};
    }
`;

const ConfirmationInfo = styled.p`
    margin: ${tokens.spacing.l} 0;
`;

export const ConfirmedPageContent = ({
    isCustomerLoginAvailable,
    isPriceEstimateAvailable,
}: ConfirmedPageContentProps) => {
    const navigate = useNavigate();
    const { uuidFromUrl } = useGetUrlParams();

    useEffect(() => {
        datadogRum.addAction('Request confirmed page visible');
    }, []);

    useEffect(() => {
        if (isCustomerLoginAvailable) {
            datadogRum.addAction('Request confirmed page with login available');
        } else {
            datadogRum.addAction(
                'Request confirmed page without login available',
            );
        }
    }, [isCustomerLoginAvailable]);

    return (
        <>
            <TitleWrapper>
                <Heading1Text>
                    {i18next.t(
                        uuidFromUrl ? 'requestSent' : 'requestConfirmed',
                    )}
                </Heading1Text>
                {!uuidFromUrl && (
                    <TitleIcon icon={'sentiment_very_satisfied'} />
                )}
            </TitleWrapper>
            <ConfirmationInfo>
                {i18next.t(
                    isPriceEstimateAvailable
                        ? 'confirmedPriceEstimateInfo'
                        : isCustomerLoginAvailable
                          ? 'requestConfirmedInfo'
                          : 'requestConfirmedInfoNoLogin',
                )}
            </ConfirmationInfo>
            {isCustomerLoginAvailable ? (
                <>
                    <PrimaryButton onClick={() => navigate(PATH_MY_PAGE)}>
                        {i18next.t(
                            isPriceEstimateAvailable
                                ? 'seePriceEstimate'
                                : 'login',
                        )}
                    </PrimaryButton>
                    <ResponseAlertMessage topMargin />
                </>
            ) : undefined}
            <NewCarBoxWrapper>
                <NewCarBox />
            </NewCarBoxWrapper>
        </>
    );
};
