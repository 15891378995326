import { styled } from 'styled-components';
import {
    breakpointDesktop,
    GhostButton,
    AlertBanner,
    SecondaryButton,
} from '@gnist/design-system';
import i18next from 'i18next';
import {
    PriceEstimateCustomerResponse,
    PriceEstimateModel,
} from '@/types/PriceEstimateModel';
import { PriceEstimateContent } from './PriceEstimateContent';
import { useUpdatePriceEstimate } from '../../queries/useUpdatePriceEstimate';
import { useParams } from 'react-router';
import { CustomerResponse } from './CustomerResponse';
import { useGetMyPageValuation } from '../../queries/useGetMyPageValuation';
import { LoadingOverlay } from '@/components/loading/LoadingOverlay';
import parse from 'html-react-parser';
import { MarketPriceCard } from './MarketPriceCard';
import { Article } from './Article';
import { useState } from 'react';
import { DeclinationResponseModal } from './DeclinationResponseModal';
import { usePurchaserEmail } from '@/utils/usePurchaserEmail';
import { NetPromoterScore } from '@/components/net-promoter-score/NetPromoterScore';
import { tokens } from '@gnist/themes/tokens.css.js';

const Box = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${tokens.color.primary};
    color: ${tokens.color['on-primary']};
    border-radius: ${tokens.radius.card};
    padding: ${tokens.spacing.l};

    a {
        color: ${tokens.color['on-primary']};
    }
`;

const Buttons = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    row-gap: ${tokens.spacing.xs};
    padding-top: ${tokens.spacing.s};

    button {
        width: 100%;
    }

    @media screen and (min-width: ${breakpointDesktop}) {
        flex-direction: row;
        column-gap: ${tokens.spacing.xs};
    }
`;

const InfoText = styled.p`
    padding-top: ${tokens.spacing.s};
    font-size: ${tokens.typeface.size.s};
    text-align: center;
    margin: 0 auto;
    max-width: 450px;
`;

const ErrorAlert = styled(AlertBanner)`
    width: calc(100% - 18px);
    margin-top: ${tokens.spacing.l};

    a {
        color: ${tokens.color['on-error-container']};
        font-weight: 500;
    }
`;

const DeclineButton = styled(GhostButton)`
    border-color: ${tokens.color.surface};
    color: ${tokens.color.surface};
`;

interface PriceEstimateProps {
    priceEstimate: PriceEstimateModel;
    showNPSBanner?: () => void;
}

export const PriceEstimate = ({
    priceEstimate,
    showNPSBanner,
}: PriceEstimateProps) => {
    const [isDeclinationModalOpen, setOpenDeclinationModal] = useState(false);
    const [isNPSBannerShowing, setShowNPSBanner] = useState(false);
    const { id } = useParams<{ id: string }>();
    const { email } = usePurchaserEmail();
    const { data: valuation } = useGetMyPageValuation(id);
    const updatePriceEstimate = useUpdatePriceEstimate(
        id,
        () => showNPSBanner && showNPSBanner(),
    );
    const { isError, isLoading } = updatePriceEstimate;

    return (
        <>
            <DeclinationResponseModal
                close={() => setOpenDeclinationModal(false)}
                showNPSBanner={() => setShowNPSBanner(true)}
                isModalOpen={isDeclinationModalOpen}
            />
            {isNPSBannerShowing && (
                <NetPromoterScore situation="PriceEstimateDeclined" />
            )}
            {isLoading && <LoadingOverlay fixed delayMillis={0} />}
            <MarketPriceCard
                marketMinPrice={priceEstimate.marketMinPrice}
                marketMaxPrice={priceEstimate.marketMaxPrice}
            />
            <Box>
                <PriceEstimateContent priceEstimate={priceEstimate} />
                {valuation?.priceEstimate?.customerResponse && !isError ? (
                    <CustomerResponse
                        customerResponse={
                            valuation.priceEstimate.customerResponse
                        }
                        showInfo={false}
                    />
                ) : (
                    <Buttons>
                        <SecondaryButton
                            onClick={() =>
                                updatePriceEstimate.mutate({
                                    customerResponse:
                                        PriceEstimateCustomerResponse.INTERESTED,
                                })
                            }
                        >
                            {i18next.t('Interested')}
                        </SecondaryButton>
                        <DeclineButton
                            onClick={() => setOpenDeclinationModal(true)}
                        >
                            {i18next.t('NotInterested')}
                        </DeclineButton>
                    </Buttons>
                )}
                <InfoText>{i18next.t('receiveOfferInfo')}</InfoText>
            </Box>
            {isError && (
                <ErrorAlert
                    type="error"
                    density="compact"
                    message={
                        parse(
                            i18next.t('generalErrorMessage', {
                                email: email,
                            }),
                        ) as string
                    }
                />
            )}
            <Article />
        </>
    );
};
