import {
    noValidation,
    required,
    ValidatorMap,
} from '@gnist/design-system/utilities/validation';
import { isValidUrl, matchesUsedCarSalesUrls } from '@/utils/form/validation';

export interface ExpectationsFormModel {
    similarCarUrl: string;
    comment: string;
}

export const initialExpectationsFormValues = {
    similarCarUrl: '',
    comment: '',
};

export const expectationsFormValidator: ValidatorMap<ExpectationsFormModel> = {
    similarCarUrl: required(isValidUrl(matchesUsedCarSalesUrls())),
    comment: noValidation(),
};
