import { getCurrentEnvironmentName } from '@/http/utils/getCurrentEnvironment';
import { styled } from 'styled-components';
import { useEffect, useState } from 'react';
import { get } from '@/http/get';
import { breakpointDesktop } from '@gnist/design-system';
import { tokens } from '@gnist/themes/tokens.css.js';

const Badge = styled.p`
    display: flex;
    position: absolute;
    left: 100px;
    align-self: center;
    font-weight: 500;
    background-color: ${tokens.palette.warning['50']};
    font-size: 12px;
    padding: 0 ${tokens.spacing.xxs};
    margin: 0 ${tokens.spacing.xs};
    border-radius: 20px;
    font-family: ${tokens.typeface.brand};
    @media screen and (min-width: ${breakpointDesktop}) {
        font-size: ${tokens.typeface.size.base};
        padding: ${tokens.spacing.base} ${tokens.spacing.s};
        left: 110px;
    }
`;

const Version = styled.span`
    padding-left: ${tokens.spacing.base};
    font-weight: 400;

    @media screen and (min-width: ${breakpointDesktop}) {
        padding-left: ${tokens.spacing.xs};
    }
`;

export const EnvBadge = () => {
    const envName = getCurrentEnvironmentName();
    const isNotProd = envName !== 'prod';
    const [apiVersion, setApiVersion] = useState('');

    useEffect(() => {
        if (!apiVersion && isNotProd) {
            void get<string>('').request.then(({ data }) => {
                const versionFromData = data?.split(' ')[1];
                setApiVersion(versionFromData);
            });
        }
    }, [apiVersion, isNotProd]);

    if (isNotProd) {
        return (
            <Badge>
                {envName.toUpperCase()}
                {apiVersion && <Version>{apiVersion}</Version>}
            </Badge>
        );
    }
    return <></>;
};
