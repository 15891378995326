import { styled } from 'styled-components';
import { breakpointTablet } from '@gnist/design-system/utilities/layout';
import { EnvBadge } from '../EnvBadge';
import { IconButton, Logo } from '@gnist/design-system';
import i18next from 'i18next';
import { AuthType, useAuth, useGetUser } from '@/auth/AuthContext';
import {
    PATH_CONSENT,
    PATH_VALUATION_REQUEST_CONSUMER,
} from '@/utils/url/routes';
import { tokens } from '@gnist/themes/tokens.css.js';

const HeaderElement = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 ${tokens.spacing.s};
    height: 56px;

    div {
        display: flex;
    }
`;

const LogoLink = styled.a`
    display: flex;
    align-self: baseline;
    text-decoration: none;

    span,
    span svg {
        @media screen and (min-width: ${breakpointTablet}) {
            height: ${tokens.size.l};
        }
        path {
            fill: ${tokens.color['on-background']};
        }
    }
`;

export const Header = ({ authType }: { authType: AuthType }) => {
    const { logout, login } = useAuth();
    const shouldFetchUser = !(
        window.location.pathname === PATH_VALUATION_REQUEST_CONSUMER ||
        window.location.pathname === PATH_CONSENT
    );
    const { data: user } = useGetUser(authType, shouldFetchUser);

    return (
        <HeaderElement>
            <div>
                <LogoLink href="https://mollerbil.no/">
                    <Logo brand="mollerbil" type="wordmark" size="m" />
                </LogoLink>
                <EnvBadge />
            </div>
            <div>
                {user?.isAuthenticated ? (
                    <IconButton
                        onClick={() => logout(authType)}
                        icon="logout"
                        showLabel="left"
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                        label={i18next.t('logout')}
                    />
                ) : (
                    <IconButton
                        onClick={() => login(authType)}
                        icon="login"
                        showLabel="left"
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                        label={i18next.t('login')}
                    />
                )}
            </div>
        </HeaderElement>
    );
};
