import { styled } from 'styled-components';
import { tokens } from '@gnist/themes/tokens.css.js';

export const Divider = styled.hr`
    width: 100%;
    border: none;
    height: 0.5px;
    background-color: ${tokens.color.outline};
    margin: ${tokens.spacing.l} 0;
    display: flex;
`;
