import { styled } from 'styled-components';
import i18next from 'i18next';
import { PriceEstimateModel } from '@/types/PriceEstimateModel';
import { numberFormat } from '@/utils/numberFormat';
import { tokens } from '@gnist/themes/tokens.css.js';

export const CardTitle = styled.h2`
    font-weight: 500;
    font-size: ${tokens.typeface.size.s};
    color: ${tokens.palette.info['60']};
    text-transform: uppercase;
    margin: 0 0 ${tokens.spacing.xxs};
`;

export const CardSubTitlePrefix = styled.p`
    font-size: ${tokens.typeface.size.base};
    font-weight: 400;
`;

export const CardSubTitle = styled.h3`
    font-size: ${tokens.typeface.size.l};
    font-weight: 500;
    line-height: ${tokens.typeface['line-height']};
    margin: 0 0 ${tokens.spacing.xxs};
`;

const InfoText = styled.p`
    line-height: ${tokens.typeface['line-height']};
    padding-bottom: ${tokens.spacing.xxs};
`;

interface PriceEstimateContentProps {
    priceEstimate: PriceEstimateModel;
}

export const PriceEstimateContent = ({
    priceEstimate,
}: PriceEstimateContentProps) => {
    return (
        <>
            <CardTitle>{i18next.t('weThinkWeCanOffer')}</CardTitle>
            <CardSubTitle>{`${numberFormat(
                priceEstimate?.offerMinPrice,
            )} - ${numberFormat(
                priceEstimate?.offerMaxPrice,
            )} kr`}</CardSubTitle>
            <InfoText>{i18next.t('priceEstimateInfoText1')}</InfoText>
            <InfoText>{i18next.t('priceEstimateInfoText2')}</InfoText>
        </>
    );
};
