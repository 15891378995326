import { styled } from 'styled-components';
import moment from 'moment';
import { Icon, LinkCard } from '@gnist/design-system';
import { DATE_PRETTY_FORMAT } from '@/utils/dateUtils';
import { ImageModel } from '@/types/ImageModel';
import i18next from 'i18next';
import { tokens } from '@gnist/themes/tokens.css.js';

const Card = styled(LinkCard)`
    display: flex;
    align-items: center;
    width: 100%;
`;

const Info = styled.div`
    display: flex;
    justify-content: center;
    flex: 1;
    flex-direction: column;

    h3 {
        font-size: ${tokens.typeface.size.base};
        font-weight: 400;
        margin: 0;
        max-height: 280%;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;
    }
    p {
        font-size: ${tokens.typeface.size.s};
    }
`;

interface Props {
    file: ImageModel;
}

export const AttachmentCard = ({ file }: Props) => {
    return (
        <Card
            href={file.url}
            density="compact"
            target="_blank"
            aria-label={`${i18next.t('attachments')} - ${file.name}`}
        >
            <Info>
                <div>
                    <h3>{file.name}</h3>
                    <p>
                        {moment(file.lastModifiedDate).format(
                            DATE_PRETTY_FORMAT,
                        )}
                    </p>
                </div>
            </Info>
            <Icon icon="file_download" />
        </Card>
    );
};
