import { styled } from 'styled-components';
import MaintenanceIcon from '../../assets/illustrations/Maintenance.svg?react';
import { DisplayText } from '@gnist/design-system';
import { Page } from '@/components/Page';
import { tokens } from '@gnist/themes/tokens.css.js';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${tokens.spacing.xxl};
    margin: ${tokens.spacing.xxl} 0;

    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100%;
`;

const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${tokens.spacing.xxs};
    text-align: center;

    align-items: center;
`;

const StyledDescription = styled.p`
    text-align: center;
`;

export const MaintenancePage = ({
    header,
    message,
}: {
    header: string;
    message: string;
}) => {
    return (
        <Page>
            <Wrapper>
                <MaintenanceIcon />
                <TextContainer>
                    <DisplayText>{header}</DisplayText>
                    <StyledDescription>{message}</StyledDescription>
                </TextContainer>
            </Wrapper>
        </Page>
    );
};
