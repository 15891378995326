import ValuatingCarImage from '../../../../assets/images/valuating-car.webp';
import { styled } from 'styled-components';
import { tokens } from '@gnist/themes/tokens.css.js';

const Wrapper = styled.div`
    padding-top: ${tokens.spacing.l};
    line-height: ${tokens.typeface['line-height']};

    h2 {
        font-size: ${tokens.typeface.size.base};
        font-weight: 500;
        margin: ${tokens.spacing.s} 0 0;
    }
    p {
        padding: ${tokens.spacing.xxs} 0;
    }
    ul {
        padding-left: ${tokens.spacing.m};
    }
`;

const Image = styled.img`
    width: 100%;
    height: auto;
    border-radius: ${tokens.size.xxs};
`;

export const Article = () => {
    return (
        <Wrapper>
            <Image src={ValuatingCarImage} alt="valuation car" />
            <h2>Fordelene med å selge til forhandler</h2>
            <p>Det er enkelt, stressfritt og vi overtar hele ansvaret.</p>
            <ul>
                <li>Du slipper å lage annonse og tilby prøvekjøring.</li>
                <li>
                    Du slipper å bruke tid på tekniske sjekker, service,
                    reparasjoner, vask og polering.
                </li>
                <li>
                    Vi tar oss av alt det praktiske rundt papirarbeid
                    (omregistrering og kontrakt).
                </li>
                <li>
                    Er det heftelser eller pant og gjeld på bilen, tar vi
                    kontakt med banken din for deg, så du slipper å tenke på
                    det.
                </li>
                <li>Du får raskt oppgjør og penger på konto.</li>
                <li>
                    Vi står ansvarlig dersom det skulle dukke opp feil med bilen
                    etter at den er solgt.
                </li>
            </ul>
        </Wrapper>
    );
};
