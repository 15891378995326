import { css, styled } from 'styled-components';
import { Icon } from '@gnist/design-system';
import i18next from 'i18next';
import { PriceEstimateCustomerResponse } from '@/types/PriceEstimateModel';
import parse from 'html-react-parser';
import { PriceOfferCustomerResponse } from '@/types/OfferModel';
import { usePurchaserEmail } from '@/utils/usePurchaserEmail';
import { tokens } from '@gnist/themes/tokens.css.js';

const Response = styled.div<{ $hasAccepted: boolean }>`
    ${(props) => css`
        display: flex;
        background-color: ${tokens.color.surface};
        width: 100%;
        justify-content: center;
        align-items: center;
        margin-top: ${tokens.spacing.l};
        border-radius: ${tokens.radius.feedback};

        svg path {
            fill: ${props.$hasAccepted
                ? tokens.color.success
                : tokens.color.error};
        }

        p {
            padding: ${tokens.spacing.xxs} 0 ${tokens.spacing.xxs}
                ${tokens.spacing.base};

            color: ${props.$hasAccepted
                ? tokens.color.success
                : tokens.color.error};
        }
    `}
`;

const Info = styled.p`
    margin-top: ${tokens.spacing.xxs};
    font-size: ${tokens.typeface.size.s};
    text-align: center;
    color: ${tokens.palette.white};
    line-height: ${tokens.typeface['line-height']};

    a {
        color: ${tokens.palette.white} !important;
    }
`;

interface CustomerResponseProps {
    customerResponse:
        | PriceEstimateCustomerResponse
        | PriceOfferCustomerResponse;
    showInfo?: boolean;
    className?: string;
}

export const CustomerResponse = ({
    customerResponse,
    showInfo = true,
    className,
}: CustomerResponseProps) => {
    const isRenewed = customerResponse === 'RenewOffer';
    const isAcceptedOffer = customerResponse === 'Accepted';
    const isPositiveResponse =
        customerResponse === 'Interested' ||
        customerResponse === 'Accepted' ||
        isRenewed;
    const { email } = usePurchaserEmail();

    return (
        <div className={className}>
            <Response $hasAccepted={isPositiveResponse}>
                <Icon icon={isPositiveResponse ? 'check' : 'close'} />
                <p>{i18next.t(customerResponse)}</p>
            </Response>
            {showInfo && (
                <Info>
                    {parse(
                        i18next.t(
                            isRenewed
                                ? 'renewOfferInfo'
                                : isPositiveResponse
                                  ? isAcceptedOffer
                                      ? 'offerAcceptedInfo'
                                      : 'acceptedInfo'
                                  : 'declinedInfo',
                            {
                                email: email,
                            },
                        ),
                    )}
                </Info>
            )}
        </div>
    );
};
