import {
    breakpointMobile,
    DetailText,
    TextContainer,
} from '@gnist/design-system';
import { useState } from 'react';
import { styled } from 'styled-components';
import i18next from 'i18next';
import { tokens } from '@gnist/themes/tokens.css.js';

const NpsScaleContainer = styled.div`
    width: 400px;
    max-width: 100%;
    margin: ${tokens.spacing.xxs} auto;

    @media screen and (max-width: ${breakpointMobile}) {
        width: auto;
    }
`;

const NpsValueButton = styled.button<{ $selected: boolean }>`
    background: ${({ $selected }) =>
        $selected ? tokens.color.primary : tokens.color.secondary};
    color: ${({ $selected }) =>
        $selected ? tokens.color['on-primary'] : tokens.color['on-secondary']};
    width: 28px;
    height: 28px;
    cursor: pointer;
    border: none;
    border-radius: 50%;
    transition: 0.15s ease all;

    &:disabled {
        cursor: auto;
    }

    &:hover:not(:disabled) {
        div {
            transform: scale(1.25);
        }
    }
`;

const FlexContainer = styled.div`
    display: flex;
    justify-content: space-between;
    column-gap: ${tokens.spacing.base};
`;

export const NpsScale = ({
    submitScore,
    score,
}: {
    submitScore: (value: number) => void;
    score: number | null;
}) => {
    const scoreValues = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    const [value, setValue] = useState<number | null>(null);

    return (
        <NpsScaleContainer>
            <FlexContainer>
                {scoreValues.map((i) => (
                    <NpsValueButton
                        $selected={value !== null && value >= i}
                        key={i}
                        onMouseEnter={() => setValue(i)}
                        onClick={() => submitScore(i)}
                        disabled={score !== null}
                    >
                        <TextContainer>{i}</TextContainer>
                    </NpsValueButton>
                ))}
            </FlexContainer>
            <FlexContainer>
                <DetailText>{i18next.t('notLikely')}</DetailText>
                <DetailText>{i18next.t('veryLikely')}</DetailText>
            </FlexContainer>
        </NpsScaleContainer>
    );
};
