import {
    matchesRegex,
    required,
    validation,
    ValidatorMap,
    validCellPhoneNumber,
    validEmail,
    validName,
    validZipCode,
} from '@gnist/design-system/utilities/validation';
import { CustomerModel } from '@/types/CustomerModel';

export type ContractFormModel = {
    firstName: string;
    lastName: string;
    phoneNumber: string;
    email: string;
    address: string;
    zipCode: string;
    city: string;
    accountNumber: string;
};

export const initialContractFormValues = (
    customer: CustomerModel | undefined,
) => {
    return {
        firstName: customer?.firstName || '',
        lastName: customer?.lastName || '',
        phoneNumber: customer?.phoneNumber || '',
        email: customer?.email || '',
        address: customer?.address || '',
        zipCode: customer?.zipCode || '',
        city: customer?.city || '',
        accountNumber: '',
    };
};

export const VALID_ACCOUNT_NUMBER = /^[0-9]{4}\s?[0-9]{2}\s?[0-9]{5}$/;

export const contractFormValidator: ValidatorMap<ContractFormModel> = {
    firstName: required(validName),
    lastName: required(validName),
    phoneNumber: required(validCellPhoneNumber),
    email: required(validEmail),
    address: required(),
    zipCode: required(validZipCode),
    city: required(),
    accountNumber: required(
        validation({
            steps: [
                matchesRegex(
                    VALID_ACCOUNT_NUMBER,
                    'Ugyldig kontonummer. Må bestå av 11 siffer.',
                ),
            ],
        }),
    ),
};
