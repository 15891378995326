import { styled } from 'styled-components';
import { PropsWithChildren } from 'react';
import { breakpointTablet } from '@gnist/design-system/utilities/layout';
import { tokens } from '@gnist/themes/tokens.css.js';

const PageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: ${tokens.spacing.l} ${tokens.spacing.l} ${tokens.spacing.xl};

    @media screen and (min-width: ${breakpointTablet}) {
        padding: ${tokens.spacing.l} 0 ${tokens.spacing.xxl};
    }
`;

const PageContent = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;

    @media screen and (min-width: ${breakpointTablet}) {
        width: 80%;
        max-width: 800px;
    }
`;

export const Page = ({
    className,
    children,
}: PropsWithChildren<{ className?: string }>) => {
    return (
        <PageWrapper className={className}>
            <PageContent>{children}</PageContent>
        </PageWrapper>
    );
};
