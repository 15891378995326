import { css, styled } from 'styled-components';
import { IconButton } from '@gnist/design-system';
import { PropsWithChildren, useEffect, useState } from 'react';
import i18next from 'i18next';
import { tokens } from '@gnist/themes/tokens.css.js';

const Wrapper = styled.div<{
    $isCollapsible: boolean;
    $isCollapsed: boolean;
    $contentHeight: string;
    $collapsedHeight: string;
}>`
    ${(props) => css`
        position: relative;
        max-height: ${props.$isCollapsed
            ? `${Number(props.$collapsedHeight) + 1}px`
            : `${Number(props.$contentHeight) + 1}px`};
        overflow: hidden;
        height: auto;
        transition: max-height 300ms ease;
        ${props.$isCollapsible &&
        !props.$isCollapsed &&
        `margin-bottom: ${tokens.spacing.s};`}
    `}
`;

const Gradient = styled.div<{ $backgroundColorToken: string }>`
    ${(props) => css`
        height: ${tokens.size.xxl};
        width: 100%;
        position: absolute;
        bottom: 0;
        background: linear-gradient(
            0deg,
            ${props.$backgroundColorToken} 0%,
            transparent 150%
        );
    `}
`;

const CollapseContainer = styled.div`
    border: 1px solid ${tokens.palette.white};
    border-color: ${tokens.palette.white};
    border-radius: 4px;
    padding: ${tokens.spacing.s};
`;

const ButtonContainer = styled.div<{ $alignment?: 'left' | 'center' }>`
    ${(props) => css`
        display: flex;
        justify-content: ${props.$alignment || 'left'};
        padding-top: ${tokens.spacing.base};
        width: 100%;
    `}
`;

export const Collapse = ({
    children,
    id,
    className,
    backgroundColorToken,
    collapsedHeight,
    withContainer,
    alignment,
}: PropsWithChildren<{
    id: string;
    className?: string;
    backgroundColorToken: string;
    collapsedHeight: string;
    withContainer?: boolean;
    alignment?: 'left' | 'center';
}>) => {
    const [isCollapsed, setIsCollapsed] = useState(true);
    const [isCollapsible, setContentIsCollapsible] = useState(false);
    const [contentHeight, setContentHeight] = useState('');

    useEffect(() => {
        const elementHeight = document.getElementById(id)?.offsetHeight;
        if (elementHeight && elementHeight > Number(collapsedHeight)) {
            setContentIsCollapsible(true);
            setContentHeight(elementHeight.toString());
        }
    }, [collapsedHeight, id]);

    const content = (
        <Wrapper
            $isCollapsible={isCollapsible}
            $isCollapsed={isCollapsed}
            $contentHeight={contentHeight || ''}
            $collapsedHeight={collapsedHeight}
        >
            <div id={id}>{children}</div>
            {isCollapsible && isCollapsed && (
                <Gradient $backgroundColorToken={backgroundColorToken} />
            )}
        </Wrapper>
    );

    return (
        <div className={className}>
            {withContainer ? (
                <CollapseContainer>{content}</CollapseContainer>
            ) : (
                content
            )}
            {isCollapsible && (
                <ButtonContainer $alignment={alignment}>
                    <IconButton
                        icon={isCollapsed ? 'expand_more' : 'expand_less'}
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                        label={i18next.t(isCollapsed ? 'readMore' : 'readLess')}
                        onClick={() => setIsCollapsed(!isCollapsed)}
                        showLabel="left"
                    />
                </ButtonContainer>
            )}
        </div>
    );
};
