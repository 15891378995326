import { styled } from 'styled-components';
import { Icon, Heading2Text } from '@gnist/design-system';
import { tokens } from '@gnist/themes/tokens.css.js';

export const Content = styled.div`
    display: flex;
    flex-direction: column;

    > div {
        display: flex;
        align-items: center;

        h3 {
            margin: 0 ${tokens.spacing.xxs} 0 0;
        }
    }

    p {
        margin-top: ${tokens.spacing.base};
    }

    svg path {
        fill: ${tokens.color.primary};
    }
`;

interface LinkCardContentProps {
    title: string;
    description: string;
}

export const LinkCardContent = ({
    title,
    description,
}: LinkCardContentProps) => {
    return (
        <Content>
            <div>
                <Heading2Text>{title}</Heading2Text>
                <Icon icon="arrow_forward" />
            </div>
            <p>{description}</p>
        </Content>
    );
};
