import i18next from 'i18next';
import { styled } from 'styled-components';
import parse from 'html-react-parser';
import { tokens } from '@gnist/themes/tokens.css.js';

const PrivacyInfo = styled.p`
    font-size: ${tokens.typeface.size.s};
    padding: ${tokens.spacing.s} 0;
`;

export const SigningStep = () => {
    return (
        <div>
            <p>{i18next.t('signingStepInfo')}</p>
            <PrivacyInfo>{parse(i18next.t('signingPrivacyInfo'))}</PrivacyInfo>
        </div>
    );
};
