import { breakpointTablet } from '@gnist/design-system';
import i18next from 'i18next';
import { ExternalLinkCard } from '@/components/ExternalLinkCard';
import { styled } from 'styled-components';
import { Subtitle } from '@/views/expectations-view/ExpectationsView';
import { ReactElement } from 'react';
import { useParams } from 'react-router';
import { useGetMyPageValuation } from '@/views/my-page/queries/useGetMyPageValuation';
import { tokens } from '@gnist/themes/tokens.css.js';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${tokens.spacing.s};

    @media screen and (min-width: ${breakpointTablet}) {
        flex-direction: row;
        & > * {
            width: 50%;
        }
    }
`;

export const SimilarCarsWrapper = ({
    secondItemElement,
}: {
    secondItemElement?: ReactElement;
}) => {
    const { id } = useParams<{ id: string }>();
    const { data: valuation } = useGetMyPageValuation(id);

    return (
        <>
            <Subtitle>{i18next.t('similarCarsTitle')}</Subtitle>
            <Wrapper>
                {valuation && (
                    <ExternalLinkCard
                        url={valuation.finnSearchUrl}
                        label={i18next.t('similarCarsFinnLink')}
                    />
                )}
                {secondItemElement ||
                    (valuation?.expectations?.similarCarUrl && (
                        <ExternalLinkCard
                            url={valuation.expectations.similarCarUrl}
                            label={i18next.t('yourSimilarCar')}
                        />
                    ))}
            </Wrapper>
        </>
    );
};
