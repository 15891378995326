import { styled } from 'styled-components';
import { AlertBanner, Heading1Text } from '@gnist/design-system';
import i18next from 'i18next';
import { useState } from 'react';
import { MyPageContractForm } from './components/MyPageContractForm';
import { ProgressBar, Step } from './components/ProgressBar';
import { useNavigate, useParams } from 'react-router';
import { ErrorContent } from '../my-page/components/ErrorContent';
import { useGetMyPageValuation } from '../my-page/queries/useGetMyPageValuation';
import { errorType } from '../my-page/utils/errorType';
import { LoadingOverlay } from '@/components/loading/LoadingOverlay';
import { PATH_MY_PAGE } from '@/utils/url/routes';
import { PageHeader } from '@/components/PageHeader';
import { Page } from '@/components/Page';
import { CustomerRole } from '@/utils/enums/CustomerRole';
import { tokens } from '@gnist/themes/tokens.css.js';

const ContractPage = styled(Page)`
    .b-page__content {
        padding: ${tokens.spacing.s} ${tokens.spacing.l};
    }
`;

export const FormHeadline = styled(Heading1Text)`
    margin: ${tokens.spacing.s} 0 ${tokens.spacing.xl};
`;

export const InfoBanner = styled(AlertBanner)`
    display: flex;
    margin: 0 0 ${tokens.spacing['3xl']};
`;

export enum MyPageContractStep {
    CUSTOMER = 'CUSTOMER',
    CAR = 'CAR',
    PAYMENT = 'PAYMENT',
    SIGNING = 'SIGNING',
}

export const formSteps: Step[] = [
    MyPageContractStep.CUSTOMER,
    MyPageContractStep.PAYMENT,
    MyPageContractStep.SIGNING,
];

export const formStepsWithSelfDeclaration: Step[] = [
    MyPageContractStep.CUSTOMER,
    MyPageContractStep.CAR,
    MyPageContractStep.PAYMENT,
    MyPageContractStep.SIGNING,
];

export const MyPageContractView = () => {
    const { id } = useParams<{ id: string }>();
    const {
        data: valuation,
        isLoading,
        isError,
        error,
    } = useGetMyPageValuation(id);
    const steps = valuation?.selfDeclaration
        ? formStepsWithSelfDeclaration
        : formSteps;
    const [activeStep, setActiveStep] = useState(steps[0]);
    const navigate = useNavigate();
    const isSellingOnBehalfOfOwner =
        valuation?.customerRole === CustomerRole.BEHALF_OF_OWNER;

    const pageTitle = (step: string) => {
        switch (step) {
            case MyPageContractStep.CUSTOMER:
                return isSellingOnBehalfOfOwner
                    ? 'stepTitleCustomerNotOwner'
                    : 'stepTitleCustomer';
            case MyPageContractStep.CAR:
                return 'stepTitleCar';
            case MyPageContractStep.PAYMENT:
                return 'stepTitlePayment';
            case MyPageContractStep.SIGNING:
                return 'stepTitleSigning';
            default:
                return '';
        }
    };

    if (isLoading) {
        return <LoadingOverlay delayMillis={0} fixed />;
    }

    return (
        <>
            {id && (
                <PageHeader
                    titleTextKey="signContract"
                    backButtonOnClick={() =>
                        void navigate(`${PATH_MY_PAGE}/${id}`)
                    }
                    backButtonTextKey="toFrontpage"
                />
            )}
            <ContractPage>
                {isError ? (
                    <ErrorContent errorType={errorType(error)} />
                ) : (
                    <>
                        <ProgressBar steps={steps} activeStep={activeStep} />
                        <FormHeadline>
                            {i18next.t(pageTitle(activeStep))}
                        </FormHeadline>
                        {isSellingOnBehalfOfOwner &&
                            activeStep === MyPageContractStep.CUSTOMER && (
                                <InfoBanner
                                    type="info"
                                    message={i18next.t('sellingOnBehalfOfInfo')}
                                    density="compact"
                                />
                            )}
                        <MyPageContractForm
                            activeStep={activeStep}
                            steps={steps}
                            setActiveStep={setActiveStep}
                        />
                    </>
                )}
            </ContractPage>
        </>
    );
};
