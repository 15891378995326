import { styled } from 'styled-components';
import i18next from 'i18next';
import { breakpointDesktop, Logo } from '@gnist/design-system';
import { PATH_CONSENT } from '@/utils/url/routes';
import { Page } from './Page';
import { tokens } from '@gnist/themes/tokens.css.js';

const FooterWrapper = styled.div`
    margin-top: auto;
`;

const FooterContent = styled(Page)`
    padding: 0;
    background-color: ${tokens.color.primary};

    > div {
        padding: ${tokens.spacing.xxl} ${tokens.spacing.s};
        max-width: 1000px;
        display: flex;

        @media screen and (min-width: ${breakpointDesktop}) {
            flex-direction: row;
            justify-content: space-between;
        }
    }
`;

const FooterLogo = styled(Logo)`
    height: ${tokens.size.l};
    min-height: ${tokens.size.l};
    width: 94px;
    svg path {
        fill: ${tokens.color['on-inverse-background']};
    }
`;

const Contact = styled.div`
    display: flex;
    flex-direction: column;

    a,
    p {
        color: ${tokens.color['on-inverse-background']};
        margin: 2px 0;
    }
    a:last-of-type {
        margin-bottom: ${tokens.spacing.m};

        @media screen and (min-width: ${breakpointDesktop}) {
            margin-bottom: 0;
        }
    }
`;

export const Footer = () => {
    return (
        <FooterWrapper>
            <FooterContent>
                <Contact>
                    <p>{i18next.t('mollerAddress')}</p>
                    <p>{i18next.t('orgNr')}</p>
                    <a href="mailto:kundeservice@moller.no">
                        Kundeservice@moller.no
                    </a>
                    <a href={PATH_CONSENT}>{i18next.t('privacyStatement')}</a>
                </Contact>
                <FooterLogo brand="mollerbil" type="wordmark" size="l" />
            </FooterContent>
        </FooterWrapper>
    );
};
