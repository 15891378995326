import { css, styled } from 'styled-components';
import { Heading1Text, Icon } from '@gnist/design-system';
import { ReactElement, useEffect, useState } from 'react';
import i18next from 'i18next';
import { StepStatus } from '@/views/my-page/components/StepStatus';
import { tokens } from '@gnist/themes/tokens.css.js';

const Wrapper = styled.div<{ $isDisabled: boolean }>`
    ${(props) => css`
        margin: ${tokens.spacing.xxs} 0 ${tokens.spacing.xl};
        ${props.$isDisabled &&
        `* { color: ${tokens.color['on-disabled']} !important; }`}
    `}
`;

const Content = styled.div<{
    $isCollapsed: boolean;
}>`
    ${(props) => css`
        position: relative;
        overflow: hidden;
        height: ${props.$isCollapsed ? '0' : 'auto'};
        margin: calc(${tokens.spacing.xxs} * -1);
        padding: ${tokens.spacing.xxs};

        > div {
            padding: ${tokens.spacing.s} 0 0;
        }
    `}
`;

const StepIndex = styled.p`
    font-weight: 500;
    font-size: ${tokens.typeface.size.s};
    line-height: ${tokens.typeface['line-height']};
    text-transform: uppercase;
`;

const Description = styled.p`
    margin-top: ${tokens.spacing.s};
`;

const Toggle = styled.button`
    background-color: transparent;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    padding: 0;
    cursor: pointer;
    color: ${tokens.color['on-surface']};

    &:disabled {
        cursor: auto;
    }

    ${Heading1Text.selector} {
        margin: 0 ${tokens.spacing.xxs} 0 0;
    }
`;

export interface MyPageStepModel {
    index: number;
    title: string;
    description?: string;
    content: ReactElement;
    isActive: boolean;
    isCompleted: boolean;
    isDisabled: boolean;
}

export const MyPageStep = ({ step }: { step: MyPageStepModel }) => {
    const [isActive, setIsActive] = useState(step.isActive);
    const [isCollapsed, setIsCollapsed] = useState(!isActive);

    useEffect(() => {
        if (!isActive && step.isActive) {
            setIsActive(true);
            if (isCollapsed) {
                setIsCollapsed(false);
            }
        }
    }, [isActive, isCollapsed, step.isActive]);

    return (
        <Wrapper $isDisabled={step.isDisabled}>
            <StepIndex>
                {i18next.t('stepIndex', { index: step.index + 1 })}
            </StepIndex>
            <Toggle
                onClick={() => setIsCollapsed(!isCollapsed)}
                disabled={step.isDisabled}
            >
                <StepStatus
                    status={
                        step.isCompleted
                            ? 'Completed'
                            : step.isActive
                              ? 'Active'
                              : 'NotStarted'
                    }
                />
                <Heading1Text>{i18next.t(step.title)}</Heading1Text>
                {!step.isDisabled && (
                    <Icon icon={isCollapsed ? 'expand_more' : 'expand_less'} />
                )}
            </Toggle>
            {step.description && step.isDisabled && !step.isCompleted && (
                <Description>{i18next.t(step.description)}</Description>
            )}
            {!step.isDisabled && (
                <Content $isCollapsed={isCollapsed}>
                    <div>{step.content}</div>
                </Content>
            )}
        </Wrapper>
    );
};
