import { RadioButton, Icon } from '@gnist/design-system';
import i18next from 'i18next';
import { LocalFormState } from '@gnist/design-system/utilities/forms/useLocalFormState.js';
import { css, styled } from 'styled-components';
import { tokens } from '@gnist/themes/tokens.css.js';

export interface FormRadioButtonProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    readonly form: LocalFormState<any>;
    readonly field: string;
    readonly className?: string;
    readonly disabled?: boolean;
    readonly boldGroupLabel?: boolean;
    readonly firstRadioLabel?: string;
    readonly firstRadioOnChange?: () => void;
    readonly secondRadioLabel?: string;
    readonly secondRadioOnChange?: () => void;
}

export const RadioGroupWrapper = styled.div<{ $boldGroupLabel?: boolean }>`
    ${(props) => css`
        &:not(:first-of-type) {
            padding-top: ${tokens.spacing.l};
        }

        > p {
            padding-bottom: ${tokens.spacing.xxs};
            font-weight: ${props.$boldGroupLabel ? '500' : '400'};
        }
    `}
`;

export const RadioGroup = styled.fieldset`
    display: flex;
    margin: 0;
    padding: 0;
    border: none;

    > div {
        padding-right: ${tokens.spacing.m};
    }
    // Temporary fix to adjust to bug in design-system making all errored radio-buttons look checked
    // TODO: Remove when fixed in design-system
    input[type='radio'][aria-invalid='true'] ~ span > span {
        background: ${tokens.color.surface};
    }
`;

export const ErrorMessage = styled.label`
    color: ${tokens.color.error};
    margin-top: ${tokens.spacing.base};
    display: flex;
    align-items: center;
    font-size: ${tokens.typeface.size.s};
    line-height: ${tokens.typeface['line-height']};
`;

export const ErrorIcon = styled(Icon)`
    color: ${tokens.color.error};
    height: ${tokens.size.s};
    width: ${tokens.size.s};
    padding-right: ${tokens.spacing.base};
    svg {
        height: calc(${tokens.size.s} * 0.666666);
    }
`;

export const FormRadioGroup = ({
    form,
    field,
    className,
    disabled = false,
    boldGroupLabel = false,
    firstRadioLabel,
    firstRadioOnChange,
    secondRadioLabel,
    secondRadioOnChange,
}: FormRadioButtonProps) => {
    const inputProps = form.inputProps(field);
    const hasValue = inputProps.value !== undefined;
    const errorMessage = inputProps.validity?.message;

    return (
        <RadioGroupWrapper
            className={className}
            $boldGroupLabel={boldGroupLabel}
        >
            <p>{i18next.t(field)}</p>
            <RadioGroup id={field}>
                <RadioButton
                    label={i18next.t(firstRadioLabel || 'yes')}
                    onChange={() => {
                        if (firstRadioOnChange) firstRadioOnChange();
                        inputProps.setValue(true);
                    }}
                    id={`${field}-true`}
                    name={field}
                    value={inputProps.value === true}
                    validity={{
                        type: errorMessage && !hasValue ? 'error' : 'none',
                    }}
                    disabled={disabled}
                />
                <RadioButton
                    label={i18next.t(secondRadioLabel || 'no')}
                    onChange={() => {
                        if (secondRadioOnChange) secondRadioOnChange();
                        inputProps.setValue(false);
                    }}
                    id={`${field}-false`}
                    name={field}
                    value={inputProps.value === false}
                    validity={{
                        type: errorMessage && !hasValue ? 'error' : 'none',
                    }}
                    disabled={disabled}
                />
            </RadioGroup>
            {errorMessage && !hasValue && (
                <ErrorMessage>
                    <ErrorIcon icon="error" />
                    {errorMessage}
                </ErrorMessage>
            )}
        </RadioGroupWrapper>
    );
};
