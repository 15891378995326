import { css, styled } from 'styled-components';
import { tokens } from '@gnist/themes/tokens.css.js';
import { Icon, IconVariantProvider } from '@gnist/design-system';

const Wrapper = styled.div`
    margin-right: ${tokens.spacing.xs};
`;

const RadioWrapper = styled.span<{ $isActive?: boolean }>`
    ${(props) => css`
        display: flex;
        box-shadow: 0 0 0 ${props.$isActive ? 2 : 1}px inset;
        border-radius: ${tokens.radius.picker};
        width: ${tokens.size.s};
        height: ${tokens.size.s};
        margin: ${tokens.spacing.base};
        align-items: center;
        justify-content: center;
    `}
`;

const FilledRadioElement = styled.span`
    border-radius: ${tokens.radius.picker};
    width: ${tokens.size.xxs};
    height: ${tokens.size.xxs};
    background-color: ${tokens.palette.black};
`;

export const StepStatus = ({
    status,
}: {
    status: 'Completed' | 'Active' | 'NotStarted';
}) => {
    return (
        <Wrapper>
            {status === 'Completed' ? (
                <IconVariantProvider variant="filled">
                    <Icon icon="success" color={tokens.color.success} />
                </IconVariantProvider>
            ) : status === 'NotStarted' ? (
                <RadioWrapper />
            ) : (
                <RadioWrapper $isActive>
                    <FilledRadioElement />
                </RadioWrapper>
            )}
        </Wrapper>
    );
};
