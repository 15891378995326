import {
    noValidation,
    required,
    ValidatorMap,
    validEmail,
    validRegistrationNumber,
} from '@gnist/design-system/utilities/validation';

export type TradeInFormModel = {
    email: string;
    registrationNumber: string;
    mileage: string;
    dealerNumber: string;
    comment?: string;
};

export const initialTradeInFormValues = {
    email: '',
    registrationNumber: '',
    mileage: '',
    dealerNumber: '',
    comment: '',
};

export const tradeInFormValidator = (): ValidatorMap<TradeInFormModel> => ({
    email: required(validEmail),
    registrationNumber: required(validRegistrationNumber),
    mileage: noValidation(),
    dealerNumber: required(),
    comment: noValidation(),
});
