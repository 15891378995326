import React, { useLayoutEffect } from 'react';
import { BrowserRouter, useRoutes } from 'react-router';
import moment from 'moment';
import 'moment/dist/locale/nb';
import { Header } from './components/header/Header';
import { ValuationRequestView } from './views/valuation-request-view/ValuationRequestView';
import { DashboardRedirect } from './views/dashboard-view/DashboardRedirect';
import {
    defineIcons,
    ExtraIconsProvider,
    IconVariantProvider,
} from '@gnist/design-system';
import { MyPageView } from './views/my-page/MyPage';
import {
    PATH_CONSENT,
    PATH_CONTRACT_DECLINED_ID,
    PATH_CONTRACT_ID,
    PATH_CONTRACT_SIGNED_ID,
    PATH_DASHBOARD,
    PATH_DASHBOARD_VALUATION,
    PATH_IMAGES_ID,
    PATH_MY_PAGE,
    PATH_MY_PAGE_ID,
    PATH_SELF_DECLARATION_ID,
    PATH_VALUATION_REQUEST_COMPANY_CAR,
    PATH_VALUATION_REQUEST_CONSUMER,
    PATH_VALUATION_REQUEST_TRADE_IN,
    PATH_PRICE_ESTIMATE_ID,
    PATH_EXPECTATIONS_ID,
} from './utils/url/routes';
import { ValuationType } from './utils/enums/ValuationType';
import { MyPageContractView } from './views/my-page-contract/MyPageContract';
import { Consent } from './views/consent/Consent';
import { AuthProvider } from './auth/AuthContext';
import { AuthView } from './auth/AuthView';
import { MyPageOverviewView } from './views/my-page/MyPageOverview';
import { ErrorPage } from './components/ErrorPage';
import { Footer } from './components/Footer';
import ExternalPage from './assets/icons/external_page.svg?react';
import { MyPageContractSigned } from './views/my-page-contract-signed/MyPageContractSigned';
import { MyPageContractDeclined } from './views/my-page-contract-declined/MyPageContractDeclined';
import { SelfDeclarationView } from './views/self-declaration/SelfDeclarationView';
import { ImagesView } from './views/images-view/ImagesView';
import { PriceEstimateView } from './views/price-estimate/PriceEstimateView';
import { globalTextStyles } from '@gnist/themes/typography.css.js';
import { mollerBil } from '@gnist/themes/themes/mollerBil.css.js';
import '@gnist/design-system/fonts/moller';
import { useFeatureFlags } from '@/utils/feature-toggle/useFeatureFlags';
import { ExpectationsView } from '@/views/expectations-view/ExpectationsView';
import { MaintenancePage } from '@/views/maintenance-view/MaintenancePage';

import './i18n/i18n';
import './App.scss';

const appIcons = defineIcons({
    external_page: {
        outlined: ExternalPage,
        options: { fit: 'width', scale: 1 },
    },
});
type AppIcons = typeof appIcons;

declare module '@gnist/design-system' {
    // eslint-disable-next-line @typescript-eslint/no-empty-object-type
    interface ExtraIcons extends AppIcons {}
}

moment.locale('nb');

export const App: React.FC = () => {
    const href = window.location.href;
    const isDealerView =
        href.includes(PATH_VALUATION_REQUEST_TRADE_IN) ||
        href.includes(PATH_VALUATION_REQUEST_COMPANY_CAR);

    const Routes = () =>
        useRoutes([
            {
                path: PATH_DASHBOARD,
                element: (
                    <AuthView authType="dealer">
                        <DashboardRedirect />
                    </AuthView>
                ),
            },
            {
                path: PATH_DASHBOARD_VALUATION,
                element: (
                    <AuthView authType="dealer">
                        <DashboardRedirect />
                    </AuthView>
                ),
            },
            {
                path: PATH_VALUATION_REQUEST_CONSUMER,
                element: (
                    <ValuationRequestView
                        valuationType={ValuationType.CONSUMER}
                    />
                ),
            },
            {
                path: PATH_VALUATION_REQUEST_TRADE_IN,
                element: (
                    <AuthView authType="dealer">
                        <ValuationRequestView
                            valuationType={ValuationType.TRADE_IN}
                        />
                    </AuthView>
                ),
            },
            {
                path: PATH_VALUATION_REQUEST_COMPANY_CAR,
                element: (
                    <AuthView authType="dealer">
                        <ValuationRequestView
                            valuationType={ValuationType.COMPANY_CAR}
                        />
                    </AuthView>
                ),
            },
            {
                path: PATH_CONSENT,
                element: <Consent />,
            },
            {
                path: PATH_MY_PAGE,
                element: (
                    <AuthView authType="customer">
                        <MyPageOverviewView />
                    </AuthView>
                ),
            },
            {
                path: PATH_MY_PAGE_ID,
                element: (
                    <AuthView authType="customer">
                        <MyPageView />
                    </AuthView>
                ),
            },
            {
                path: PATH_SELF_DECLARATION_ID,
                element: (
                    <AuthView authType="customer">
                        <SelfDeclarationView />
                    </AuthView>
                ),
            },
            {
                path: PATH_IMAGES_ID,
                element: (
                    <AuthView authType="customer">
                        <ImagesView />
                    </AuthView>
                ),
            },
            {
                path: PATH_EXPECTATIONS_ID,
                element: (
                    <AuthView authType="customer">
                        <ExpectationsView />
                    </AuthView>
                ),
            },
            {
                path: PATH_PRICE_ESTIMATE_ID,
                element: (
                    <AuthView authType="customer">
                        <PriceEstimateView />
                    </AuthView>
                ),
            },
            {
                path: PATH_CONTRACT_ID,
                element: (
                    <AuthView authType="customer">
                        <MyPageContractView />
                    </AuthView>
                ),
            },
            {
                path: PATH_CONTRACT_SIGNED_ID,
                element: (
                    <AuthView authType="customer">
                        <MyPageContractSigned />
                    </AuthView>
                ),
            },
            {
                path: PATH_CONTRACT_DECLINED_ID,
                element: (
                    <AuthView authType="customer">
                        <MyPageContractDeclined />
                    </AuthView>
                ),
            },
            {
                path: '*',
                element: (
                    <ErrorPage
                        icon="sentiment_very_dissatisfied"
                        titleTextKey="pageNotFoundTitle"
                        descriptionTextKey="pageNotFoundDescription"
                    />
                ),
            },
        ]);

    useLayoutEffect(() => {
        document.body.classList.add(mollerBil);
        document.body.classList.add(...globalTextStyles);
    }, []);
    const { maintenancePage } = useFeatureFlags();
    return (
        <ExtraIconsProvider icons={appIcons}>
            <IconVariantProvider variant="outlined">
                <AuthProvider>
                    {maintenancePage && maintenancePage.header ? (
                        <>
                            <MaintenancePage
                                header={maintenancePage.header}
                                message={maintenancePage.message}
                            ></MaintenancePage>
                            <Footer />
                        </>
                    ) : (
                        <BrowserRouter>
                            <Header
                                authType={isDealerView ? 'dealer' : 'customer'}
                            />
                            <Routes />
                            <Footer />
                        </BrowserRouter>
                    )}
                </AuthProvider>
            </IconVariantProvider>
        </ExtraIconsProvider>
    );
};
