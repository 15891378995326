import { AlertBanner } from '@gnist/design-system';
import { css, styled } from 'styled-components';
import { useFeatureFlags } from '@/utils/feature-toggle/useFeatureFlags';
import { tokens } from '@gnist/themes/tokens.css.js';

export const Alert = styled(AlertBanner)<{ $topMargin?: boolean }>`
    ${(props) => css`
        ${props.$topMargin
            ? `margin-top: ${tokens.spacing.xl}`
            : `margin-bottom: ${tokens.spacing.m}`};
    `}
`;

export const ResponseAlertMessage = ({
    topMargin,
}: {
    topMargin?: boolean;
}) => {
    const { responseAlertMessage } = useFeatureFlags();

    if (responseAlertMessage && responseAlertMessage.message)
        return (
            <Alert
                message={responseAlertMessage.message}
                heading={responseAlertMessage.heading || ''}
                type={responseAlertMessage.type || 'info'}
                $topMargin={topMargin}
            />
        );
    else return <></>;
};
