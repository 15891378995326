import {
    useLocalFormState,
    FormProvider,
    formInputs,
} from '@gnist/design-system';
import {
    noValidation,
    required,
    validRegistrationNumber,
} from '@gnist/design-system/utilities/validation';
import i18next from 'i18next';
import { useGetCar } from '../../utils/useGetCar';
import { styled } from 'styled-components';
import { breakpointTablet } from '@gnist/design-system/utilities/layout';
import { FormButtons } from './FormButtons';
import { useVehicleApprovals } from '../../queries/useVehicleApprovals';
import { useEffect } from 'react';
import { RejectionType } from '@/utils/enums/RejectionType';
import { LoadingOverlay } from '@/components/loading/LoadingOverlay';
import { FormErrorAlert } from './FormErrorAlert';
import { useGetUrlParams } from '../../utils/useGetUrlParams';
import { SvvDownAlertMessage } from '@/components/SvvDownAlertMessage';
import { tokens } from '@gnist/themes/tokens.css.js';

export type CarStepFormModel = {
    registrationNumber: string;
    mileage: string;
    purchaseHelpDesired: boolean;
};

export const initialCarStepFormValues: CarStepFormModel = {
    registrationNumber: '',
    mileage: '',
    purchaseHelpDesired: false,
};

const { TextField, Checkbox } = formInputs<CarStepFormModel>();

export const InputWrapper = styled.div`
    display: flex;
    flex-direction: column;

    @media screen and (min-width: ${breakpointTablet}) {
        width: 50%;
    }
`;

export const SubTitle = styled.h2`
    display: flex;
    font-size: ${tokens.typeface.size.base};
    font-weight: 500;
    margin-top: ${tokens.spacing.xl};
`;

export const InfoItem = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: ${tokens.typeface.size.base};
    margin: ${tokens.spacing.base} 0 ${tokens.spacing.xs};

    dd {
        font-weight: 500;
        margin: ${tokens.spacing.base} 0 0 0;
    }
`;

interface CarStepProps {
    setRejectionReasonType: (type: RejectionType) => void;
    goToNextStep: () => void;
    setCar: (data: CarStepFormModel) => void;
    car: CarStepFormModel;
    setCarModel: (carModel: string) => void;
    setIsElectric: (isElectric: boolean) => void;
}

export const CarStep = ({
    setRejectionReasonType,
    goToNextStep,
    setCar,
    car,
    setCarModel,
    setIsElectric,
}: CarStepProps) => {
    const { regNoFromUrl, srcFromUrl, uuidFromUrl } = useGetUrlParams();
    const isFromBilhold =
        srcFromUrl === 'bilhold' || srcFromUrl === 'bilholdPriceEstimate';
    const form = useLocalFormState<CarStepFormModel>(
        {
            registrationNumber: regNoFromUrl || car.registrationNumber,
            mileage: car.mileage,
            purchaseHelpDesired: car.purchaseHelpDesired,
        },
        {
            registrationNumber: required(validRegistrationNumber),
            mileage: required(),
            purchaseHelpDesired: noValidation(),
        },
    );
    const {
        data: fetchedCar,
        error: getCarError,
        isFetching: isFetchingCar,
        status: getCarStatus,
    } = useGetCar(form.state.validated.registrationNumber);

    const validatedRegNo = form.state.validated.registrationNumber;
    const getVehicleApproval = useVehicleApprovals(
        form.state.validated.registrationNumber,
        form.state.validated.mileage,
        form.state.validated.purchaseHelpDesired,
        uuidFromUrl ? true : false,
    );
    const {
        data,
        isSuccess,
        isLoading,
        isError: vehicleApprovalError,
    } = getVehicleApproval;

    useEffect(() => {
        if (isSuccess) {
            if (data?.rejected) {
                setRejectionReasonType(data.rejectionReason.type);
            } else {
                setCar({
                    registrationNumber:
                        form.state.validated.registrationNumber || '',
                    mileage: form.state.validated.mileage || '',
                    purchaseHelpDesired:
                        form.state.validated.purchaseHelpDesired || false,
                });
                goToNextStep();
                if (fetchedCar) {
                    setCarModel(`${fetchedCar.brand} ${fetchedCar.model}`);
                    setIsElectric(fetchedCar.isElectric);
                }
            }
        }
    }, [
        data?.rejected,
        data?.rejectionReason,
        form.state.validated.mileage,
        form.state.validated.purchaseHelpDesired,
        form.state.validated.registrationNumber,
        goToNextStep,
        isSuccess,
        setCar,
        setCarModel,
        fetchedCar,
        setRejectionReasonType,
        setIsElectric,
    ]);

    const carFetchedWithSvvData = getCarStatus === 'success' && !!fetchedCar;
    const carFetchedWithoutSvvData = getCarStatus === 'success' && !fetchedCar;

    return (
        <FormProvider
            id="carStepForm"
            form={form}
            onSubmit={() =>
                !isFetchingCar && !getCarError && getVehicleApproval.mutate()
            }
        >
            {isLoading && <LoadingOverlay fixed delayMillis={0} />}
            <InputWrapper>
                {regNoFromUrl ? (
                    <InfoItem>
                        <dt>{i18next.t('regNo')}</dt>
                        <dd>{regNoFromUrl}</dd>
                    </InfoItem>
                ) : (
                    <TextField
                        field="registrationNumber"
                        label={i18next.t('regNo')}
                        helperText={
                            carFetchedWithSvvData
                                ? `${fetchedCar.brand} ${fetchedCar.model}`
                                : undefined
                        }
                        validity={
                            carFetchedWithSvvData
                                ? {
                                      type: 'success',
                                      message: `${fetchedCar.brand} ${fetchedCar.model}`,
                                  }
                                : getCarError && validatedRegNo
                                  ? {
                                        type: 'error',
                                        message: i18next.t('invalidCarMessage'),
                                    }
                                  : undefined
                        }
                    />
                )}
                <TextField
                    field="mileage"
                    label={i18next.t('mileage')}
                    suffix={i18next.t('mileageSuffix')}
                    inputMode="numeric"
                    helperText={i18next.t('mileageHelpText')}
                />
            </InputWrapper>
            <SubTitle>{i18next.t('needNewCar')}</SubTitle>
            <Checkbox
                label={i18next.t('purchaseHelpDesired')}
                field="purchaseHelpDesired"
            />
            {vehicleApprovalError && <FormErrorAlert />}
            {carFetchedWithoutSvvData && <SvvDownAlertMessage />}
            <FormButtons
                primaryAction={{
                    textKey: 'next',
                }}
                secondaryAction={{
                    textKey: 'cancel',
                    onClick: () =>
                        (window.location.href = isFromBilhold
                            ? 'https://www.mittbilhold.no/'
                            : 'https://mollerbil.no/'),
                }}
            />
        </FormProvider>
    );
};
