import { ChangeEvent, useEffect, useRef, useState } from 'react';
import {
    breakpointDesktop,
    GhostButton,
    AlertBanner,
    PrimaryButton,
    Icon,
    Heading2Text,
} from '@gnist/design-system';
import i18next from 'i18next';
import { css, styled } from 'styled-components';
import { ImagePreview } from './ImagePreview';
import { SaveImages } from './SaveImages';
import { ImageModel } from '@/types/ImageModel';
import { useParams } from 'react-router';
import { useGetMyPageValuation } from '../../my-page/queries/useGetMyPageValuation';
import { LoadingOverlay } from '@/components/loading/LoadingOverlay';
import { handleMultipleFiles } from '../utils/uploadFilesUtils';
import { useGetImages } from '../queries/useGetImages';
import parse from 'html-react-parser';
import { useSaveImages } from '../queries/useSaveImages';
import { datadogRum } from '@datadog/browser-rum';
import { usePurchaserEmail } from '@/utils/usePurchaserEmail';
import { atoms } from '@gnist/themes/atoms.css.js';
import { tokens } from '@gnist/themes/tokens.css.js';

const UploadImagesWrapper = styled.div<{ $editMode: boolean }>`
    ${({ $editMode }) =>
        $editMode &&
        `background-color: ${tokens.palette.white};
            padding: ${tokens.spacing.s} ${tokens.spacing.s} 0;
            border-radius: ${tokens.radius.card};`}
    margin-top: ${tokens.spacing.xl};
`;

const GetImagesErrorBanner = styled(AlertBanner)`
    margin-top: ${tokens.spacing.xl};
`;

const SaveImagesErrorBanner = styled(AlertBanner)`
    margin-top: ${tokens.spacing.s};
`;

const UploadButtonWrapper = styled.div<{ $hasImages: boolean }>`
    ${({ $hasImages }) => css`
        display: flex;
        justify-content: center;
        margin-top: ${$hasImages ? tokens.spacing.s : 0};

        button {
            width: 100%;

            @media screen and (min-width: ${breakpointDesktop}) {
                width: auto;
                min-width: 230px;
            }
        }

        input {
            display: none;
        }
    `}
`;

export const UploadImages = () => {
    const { id } = useParams<{ id: string }>();
    const { data: valuation } = useGetMyPageValuation(id);
    const { email } = usePurchaserEmail();
    const inputFileRef = useRef<HTMLInputElement>(null);
    const {
        data: images,
        isSuccess: fetchIsSuccess,
        isLoading: fetchIsLoading,
        isError: fetchHasError,
    } = useGetImages(!!valuation?.hasImages);
    const [isUploading, setIsUploading] = useState(false);
    const [selectedImages, setSelectedImages] = useState<ImageModel[]>([]);
    const [isEditing, setIsEditing] = useState(false);
    const saveImages = useSaveImages(id, () => {
        datadogRum.addAction('Save images success');
        setIsEditing(false);
    });
    const {
        isError: saveHasError,
        isLoading: saveIsLoading,
        reset: resetSaving,
    } = saveImages;

    const selectImages = async (event: ChangeEvent<HTMLInputElement>) => {
        const uploadedFiles = (await handleMultipleFiles(
            event.target.files,
        )) as ImageModel[];
        setIsUploading(false);
        setIsEditing(true);
        return setSelectedImages([...selectedImages, ...uploadedFiles]);
    };

    const handleRemoveImage = (image: ImageModel) => {
        const indexOfItem = selectedImages.indexOf(image);
        const updatedArray = selectedImages.filter(
            (object) => selectedImages.indexOf(object) !== indexOfItem,
        );

        return setSelectedImages(updatedArray);
    };

    const onUploadClick = () => {
        resetSaving();
        if (inputFileRef) inputFileRef.current?.click();
    };

    useEffect(() => {
        if (fetchIsSuccess) setSelectedImages(images);
    }, [images, fetchIsSuccess]);

    return (
        <>
            {fetchHasError && (
                <GetImagesErrorBanner
                    message={
                        parse(
                            i18next.t('fetchImagesError', {
                                email: email,
                            }),
                        ) as string
                    }
                    type="error"
                    density="compact"
                />
            )}
            <UploadImagesWrapper $editMode={isEditing}>
                {isEditing && (
                    <Heading2Text
                        className={atoms({ marginBottom: 's' })}
                        $as="h2"
                    >
                        {images?.length ? 'Rediger bilder' : 'Last opp bilder'}
                    </Heading2Text>
                )}
                {selectedImages &&
                    selectedImages.map((image, index) => (
                        <ImagePreview
                            key={index}
                            image={image}
                            removeImage={() => {
                                resetSaving();
                                handleRemoveImage(image);
                            }}
                            isEditing={isEditing}
                        />
                    ))}
                {(isUploading ||
                    (fetchIsLoading && valuation?.hasImages) ||
                    saveIsLoading) && <LoadingOverlay fixed delayMillis={0} />}
                <UploadButtonWrapper $hasImages={!!selectedImages.length}>
                    <input
                        ref={inputFileRef}
                        type="file"
                        multiple
                        accept="image/*"
                        onChange={(e) => {
                            setIsUploading(true);
                            void selectImages(e);
                        }}
                    />
                    {images?.length || selectedImages.length ? (
                        isEditing ? (
                            <GhostButton onClick={() => onUploadClick()}>
                                {i18next.t('uploadMoreImages')}
                            </GhostButton>
                        ) : (
                            <GhostButton onClick={() => setIsEditing(true)}>
                                <Icon icon="edit" />
                                {i18next.t('editImages')}
                            </GhostButton>
                        )
                    ) : isEditing ? (
                        <GhostButton onClick={() => onUploadClick()}>
                            {i18next.t('uploadMoreImages')}
                        </GhostButton>
                    ) : (
                        <PrimaryButton onClick={() => onUploadClick()}>
                            {i18next.t('uploadImages')}
                        </PrimaryButton>
                    )}
                </UploadButtonWrapper>
                {saveHasError && (
                    <SaveImagesErrorBanner
                        message={
                            parse(
                                i18next.t('uploadImagesError', {
                                    email: email,
                                }),
                            ) as string
                        }
                        type="error"
                        density="compact"
                    />
                )}
                {isEditing && (
                    <SaveImages
                        selectedImages={selectedImages}
                        onSaveClick={() => saveImages.mutate(selectedImages)}
                        onCancelClick={() => {
                            setIsEditing(false);
                            setSelectedImages(images ?? []);
                        }}
                    />
                )}
            </UploadImagesWrapper>
        </>
    );
};
