import i18next from 'i18next';
import {
    characterCount,
    formInputs,
    FormProvider,
    PrimaryButton,
    useLocalFormState,
} from '@gnist/design-system';
import { useEffect, useState } from 'react';
import { sortedDealers } from '../utils/sortedDealers';
import { useGetUser } from '@/auth/AuthContext';
import { DealerModel } from '@/types/DealerModel';
import { useGetCar } from '../utils/useGetCar';
import {
    initialTradeInFormValues,
    TradeInFormModel,
    tradeInFormValidator,
} from '../utils/tradeInFormUtils';
import { InputLayout, SectionTitle, SubmitLayout } from './CompanyCarForm';
import { RequestModel } from '../queries/useConfirmRequest';
import { SvvDownAlertMessage } from '@/components/SvvDownAlertMessage';

interface TradeInFormProps {
    readonly dealers: DealerModel[] | undefined;
    readonly handleOnSubmit: (
        data: RequestModel,
        selectedDealer?: DealerModel,
    ) => void;
}

const { TextField, TextArea, SingleSelect } = formInputs<TradeInFormModel>();

export const TradeInForm = ({ dealers, handleOnSubmit }: TradeInFormProps) => {
    const form = useLocalFormState<TradeInFormModel>(
        initialTradeInFormValues,
        tradeInFormValidator(),
        { showNecessityOn: 'required' },
    );
    const [isPrepopulated, setIsPrepopulated] = useState(false);
    const validatedRegNo = form.state.validated.registrationNumber;
    const { data: user } = useGetUser('dealer');
    const account = user?.claims;
    const {
        data: car,
        error: getCarError,
        status: getCarStatus,
        isFetching: isFetcingCar,
    } = useGetCar(form.state.validated.registrationNumber);

    useEffect(() => {
        if (isPrepopulated || !account) return;
        if (form.inputProps('email').value !== '') return;
        form.inputProps('email').setValue(account.email);
        setIsPrepopulated(true);
    }, [account, isPrepopulated, form]);

    const selectedDealer = () => {
        const selectedDealerNumber = form.inputProps('dealerNumber').value;
        return dealers?.find((d) => d.number === selectedDealerNumber);
    };

    const carFetchedWithSvvData = getCarStatus === 'success' && !!car;
    const carFetchedWithoutSvvData = getCarStatus === 'success' && !car;

    return (
        <FormProvider
            id="requestForm"
            form={form}
            onSubmit={(data) =>
                !isFetcingCar &&
                !getCarError &&
                handleOnSubmit(data, selectedDealer())
            }
        >
            <SectionTitle>{i18next.t('carSection')}</SectionTitle>
            <InputLayout>
                <TextField
                    field="registrationNumber"
                    label={i18next.t('regNo')}
                    helperText={
                        carFetchedWithSvvData ? `${car.brand} ${car.model}` : ''
                    }
                    validity={
                        carFetchedWithSvvData
                            ? {
                                  type: 'success',
                                  message: `${car?.brand} ${car?.model}`,
                              }
                            : getCarError && validatedRegNo
                              ? {
                                    type: 'error',
                                    message: i18next.t('invalidCarMessage'),
                                }
                              : undefined
                    }
                />
                <TextField
                    field="mileage"
                    label={i18next.t('mileage')}
                    suffix={i18next.t('mileageSuffix')}
                    inputMode="numeric"
                    helperText={i18next.t('mileageHelpText')}
                />
                <TextArea
                    field="comment"
                    label={i18next.t('tradeInComment')}
                    maxLength={300}
                    counterText={characterCount(
                        form?.state?.raw?.comment || '',
                        300,
                    )}
                />
            </InputLayout>
            <SectionTitle>
                {i18next.t('customerSectionInTradeInForm')}
            </SectionTitle>
            <InputLayout>
                <TextField
                    field="email"
                    label={i18next.t('email')}
                    type="email"
                    autoComplete="email"
                    inputMode="email"
                />
                <SingleSelect
                    field="dealerNumber"
                    label={i18next.t('chooseDealer')}
                    options={
                        dealers
                            ? [
                                  { value: '', label: '' },
                                  ...sortedDealers(dealers).map((dealer) => ({
                                      value: dealer.number,
                                      label: dealer.name,
                                  })),
                              ]
                            : []
                    }
                />
            </InputLayout>
            <SubmitLayout>
                {carFetchedWithoutSvvData && <SvvDownAlertMessage />}
                <PrimaryButton type="submit">
                    {i18next.t('sendRequest')}
                </PrimaryButton>
            </SubmitLayout>
        </FormProvider>
    );
};
