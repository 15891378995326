import { LoadingOverlay } from '@/components/loading/LoadingOverlay';
import { PriceOfferCustomerResponse } from '@/types/OfferModel';
import { usePurchaserEmail } from '@/utils/usePurchaserEmail';
import { useUpdatePriceOffer } from '@/views/my-page/queries/useUpdatePriceOffer';
import {
    BodyText,
    AlertBanner,
    formInputs,
    FormProvider,
    Modal,
    RadioButton,
    useLocalFormState,
} from '@gnist/design-system';
import i18next from 'i18next';
import { useParams } from 'react-router';
import { styled } from 'styled-components';
import parse from 'html-react-parser';
import {
    DeclinationOfferResponseFormModel,
    declinationOfferResponseFormValidator,
    initialDeclinationOfferResponseFormValues,
} from './priceOfferUtils';
import { tokens } from '@gnist/themes/tokens.css.js';

const Title = styled(BodyText)`
    font-size: ${tokens.typeface.size.l};
    font-weight: 500;
`;

const RadioButtonWrapper = styled.fieldset`
    border: 0;
    padding: 0;
    margin: 0;
    display: grid;
    row-gap: ${tokens.spacing.xs};
`;

const Description = styled.p`
    margin: ${tokens.spacing.xxs} 0 ${tokens.spacing.l};
`;

const Footer = styled.p`
    margin: ${tokens.spacing.xxs} 0 ${tokens.spacing.l};
    text-align: center;
    color: ${tokens.color.primary};
    font-weight: ${tokens.typeface.weight.medium};
`;

const Legend = styled.legend`
    margin-bottom: ${tokens.spacing.xs};
    font-weight: 500;
`;

const ErrorBanner = styled(AlertBanner)`
    margin-top: ${tokens.spacing.m};

    a {
        color: ${tokens.color['on-error-container']};
    }
`;

const ModalWrapper = styled(Modal)`
    width: 496px;
    padding-bottom: ${tokens.spacing.l};
`;

const CommentWrapper = styled.div`
    margin-top: ${tokens.spacing.s};

    > * {
        width: 100%;
    }
`;

const declinationResponses: PriceOfferCustomerResponse[] = [
    PriceOfferCustomerResponse.DECLINED_PRICE_TOO_LOW,
    PriceOfferCustomerResponse.DECLINED_VALUE_EXPLORATION,
    PriceOfferCustomerResponse.DECLINED_SELLING_TO_SOMEONE_ELSE,
    PriceOfferCustomerResponse.DECLINED_OTHER,
];

interface DeclinationOfferResponseModalProps {
    readonly offerId: string;
    readonly close: () => void;
    readonly showNPSBanner: () => void;
    readonly isModalOpen: boolean;
}

export const DeclinationOfferResponseModal = ({
    close,
    showNPSBanner,
    isModalOpen,
    offerId,
}: DeclinationOfferResponseModalProps) => {
    const { id } = useParams<{ id: string }>();
    const { email } = usePurchaserEmail();
    const updateOffer = useUpdatePriceOffer(id, offerId, () => {
        close();
        showNPSBanner();
    });
    const { isError, isLoading } = updateOffer;

    const form = useLocalFormState<DeclinationOfferResponseFormModel>(
        initialDeclinationOfferResponseFormValues,
        declinationOfferResponseFormValidator(),
    );

    const inputProps = form.inputProps('selectedResponse');

    const { TextArea } = formInputs<DeclinationOfferResponseFormModel>();

    const handleSubmit = () =>
        form.state.isValid
            ? updateOffer.mutate({
                  customerResponse: form.state.validated.selectedResponse,
                  customerResponseComment:
                      form.state.validated.responseComment.trim() || undefined,
              })
            : form.showAllErrors();

    return (
        <ModalWrapper
            handleClose={close}
            isOpen={isModalOpen}
            primaryAction={{
                onClick: () => handleSubmit(),
                label: i18next.t('sendReason'),
            }}
            secondaryAction={{
                onClick: close,
                label: i18next.t('cancel'),
            }}
            size="large"
        >
            {isLoading && <LoadingOverlay delayMillis={0} />}
            <Title>{i18next.t('declinationTitle')}</Title>
            <Description>
                {i18next.t('offerDeclinationModalDescription')}
            </Description>
            <Legend>{i18next.t('offerDeclinationModalQuestion')}</Legend>
            <FormProvider
                id="declinationOfferResponseForm"
                form={form}
                hideNecessityText
            >
                <RadioButtonWrapper>
                    {declinationResponses.map((response) => (
                        <div key={response}>
                            <RadioButton
                                key={response}
                                label={i18next.t(response)}
                                id={response}
                                name="rejectionReasonRadio"
                                value={response === inputProps.value}
                                onChange={() => inputProps.setValue(response)}
                            />
                        </div>
                    ))}
                </RadioButtonWrapper>
                <CommentWrapper>
                    <TextArea
                        field="responseComment"
                        label={i18next.t('explainWhy')}
                        maxLength={200}
                        helperText={i18next.t('declinationHelperText')}
                    />
                </CommentWrapper>
            </FormProvider>
            <Description>{i18next.t('declinationOfferParked')}</Description>
            <Footer>{i18next.t('declinationFooter')}</Footer>
            {isError && (
                <ErrorBanner
                    message={
                        parse(
                            i18next.t('generalErrorMessage', {
                                email: email,
                            }),
                        ) as string
                    }
                    type="error"
                    density="compact"
                />
            )}
        </ModalWrapper>
    );
};
