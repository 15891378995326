import i18next from 'i18next';
import { css, styled } from 'styled-components';
import CalendarIcon from '../../../assets/icons/calendar-outlined.svg?react';
import moment from 'moment';
import { DATE_FORMAT, DATE_MONTH_FORMAT } from '@/utils/dateUtils';
import { numberFormat } from '@/utils/numberFormat';
import {
    AlertBanner,
    breakpointDesktop,
    Icon,
    IconButton,
    PrimaryButton,
} from '@gnist/design-system';
import { PATH_CONTRACT } from '@/utils/url/routes';
import { useGetMyPageValuation } from '../queries/useGetMyPageValuation';
import { useParams, useNavigate } from 'react-router';
import { useEffect } from 'react';
import { datadogRum } from '@datadog/browser-rum';
import parse from 'html-react-parser';
import { getCurrentEnvironment } from '@/http/utils/getCurrentEnvironment';
import format from 'string-format';
import { MY_VALUATION_CONTRACT_DOCUMENT } from '@/http/endpoints';
import { usePurchaserEmail } from '@/utils/usePurchaserEmail';
import { tokens } from '@gnist/themes/tokens.css.js';

const HandoverDate = styled.div`
    display: flex;
    align-items: center;
    align-self: center;
    p {
        padding: 0 ${tokens.spacing.base} 0 ${tokens.spacing.xxs};
    }

    @media screen and (min-width: ${breakpointDesktop}) {
        align-self: flex-start;
    }
`;

const Debts = styled.dl`
    margin: 0;
    display: flex;
    flex-direction: column;
`;

const DebtItem = styled.div`
    display: flex;
    justify-content: space-between;
    padding: ${tokens.spacing.xxs} 0 0 ${tokens.spacing.s};
`;

const InfoWrapper = styled.dl<{ $topPadding: boolean }>`
    ${(props) => css`
        display: flex;
        flex-direction: column;
        margin: 0;
        padding-top: ${props.$topPadding ? tokens.spacing.l : '0'};

        @media screen and (min-width: ${breakpointDesktop}) {
            width: 50%;
            min-width: 350px;
        }
    `}
`;

const InfoItem = styled.div<{
    $bold?: boolean;
    $borderBottom?: boolean;
    $wrapColumn?: boolean;
}>`
    ${(props) => css`
        display: flex;
        flex-direction: ${props.$wrapColumn ? 'column' : 'row'};
        padding: ${tokens.spacing.base} 0
            ${props.$borderBottom ? tokens.spacing.xxs : tokens.spacing.base} 0;
        margin-bottom: ${props.$borderBottom ? tokens.spacing.base : 0};
        justify-content: space-between;
        border-bottom: 1px solid ${tokens.color.outline};
        border-bottom-width: ${props.$borderBottom ? '1px' : 0};

        dt,
        dd {
            font-weight: ${props.$bold ? 500 : 400};
        }

        @media screen and (min-width: ${breakpointDesktop}) {
            dt:first-of-type {
                padding-right: ${tokens.spacing.xxl};
            }
        }
    `}
`;

const StartButton = styled(PrimaryButton)`
    width: 100%;
    margin-top: ${tokens.spacing.xs};
`;

const Alert = styled(AlertBanner)`
    margin-top: ${tokens.spacing.l};
`;

const DownloadContract = styled(IconButton)`
    margin: ${tokens.spacing.l} 0;
    align-self: center;

    @media screen and (min-width: ${breakpointDesktop}) {
        align-self: flex-start;
    }

    svg path {
        fill: ${tokens.color.primary};
    }
`;

export const ContractInfo = () => {
    const navigate = useNavigate();
    const { id } = useParams<{ id: string }>();
    const { data: valuation } = useGetMyPageValuation(id);
    const { email } = usePurchaserEmail();
    const signingPageUrl = valuation?.contract?.signingPageUrl;
    const status = valuation?.contract?.status;
    const deadlineDate = valuation?.contract?.deadlineDate;
    const isSigned = status === 'Signed';
    const isRejected = status === 'Rejected';
    const isExpired = status === 'Expired';
    const isPending = status === 'Pending';
    const { apiUrl } = getCurrentEnvironment();
    const handleDownloadContract = (id: string) =>
        window.open(
            `${apiUrl}/${format(MY_VALUATION_CONTRACT_DOCUMENT, id)}`,
            '_blank',
        );

    useEffect(() => {
        if (!isSigned) {
            datadogRum.addAction('Start signing contract button visible');
        }
    }, [isSigned]);

    return (
        <>
            {valuation?.contract && (
                <div>
                    {!isSigned && (
                        <HandoverDate>
                            <CalendarIcon />
                            <p>{`${i18next.t('handoverDate')}: ${moment(
                                valuation.contract.handoverDate,
                            ).format(DATE_FORMAT)}`}</p>
                        </HandoverDate>
                    )}
                    <InfoWrapper $topPadding={!isSigned}>
                        {isSigned ? (
                            <InfoItem $borderBottom>
                                <dt>{i18next.t('handoverDate')}</dt>
                                <dd>
                                    {moment(
                                        valuation.contract.handoverDate,
                                    ).format(DATE_FORMAT)}
                                </dd>
                            </InfoItem>
                        ) : (
                            <InfoItem>
                                <dt>{i18next.t('buyingPrice')}</dt>
                                <dd>{`${numberFormat(
                                    valuation.contract.purchasePrice,
                                )} ${i18next.t('priceSuffix')}`}</dd>
                            </InfoItem>
                        )}
                        <InfoItem
                            $borderBottom
                            $wrapColumn={
                                valuation.contract.debts &&
                                valuation.contract.debts.length > 0
                            }
                        >
                            <dt>{i18next.t('carDebts')}</dt>
                            {valuation.contract.debts?.length ? (
                                <Debts>
                                    {valuation.contract.debts?.map(
                                        (d, index) => (
                                            <DebtItem key={index}>
                                                <dt>{d.bank}</dt>
                                                <dd>{`-${numberFormat(
                                                    d.amount,
                                                )} ${i18next.t(
                                                    'priceSuffix',
                                                )}`}</dd>
                                            </DebtItem>
                                        ),
                                    )}
                                </Debts>
                            ) : (
                                <dd>{i18next.t('none')}</dd>
                            )}
                        </InfoItem>
                        <InfoItem $borderBottom>
                            <dt>{i18next.t('customerPaymentAmount')}</dt>
                            <dd>{`${numberFormat(
                                valuation.contract.customerPaymentAmount || 0,
                            )} ${i18next.t('priceSuffix')}`}</dd>
                        </InfoItem>
                    </InfoWrapper>
                    {(isPending || isExpired) && (
                        <Alert
                            type={isExpired ? 'error' : 'info'}
                            message={
                                isExpired
                                    ? (parse(
                                          i18next.t('deadlineExpiredInfo', {
                                              email: email,
                                          }),
                                      ) as string)
                                    : i18next.t('deadlineInfo', {
                                          date: moment(deadlineDate).format(
                                              DATE_MONTH_FORMAT,
                                          ),
                                      })
                            }
                        />
                    )}
                    {!isSigned && !signingPageUrl && (
                        <StartButton
                            onClick={() =>
                                navigate(
                                    `${PATH_CONTRACT}/${valuation?.valuationId}`,
                                )
                            }
                            disabled={!!signingPageUrl || isExpired}
                        >
                            {i18next.t('startSigning')}
                            <Icon icon="arrow_forward" />
                        </StartButton>
                    )}
                    {!!signingPageUrl &&
                        !isSigned &&
                        !isRejected &&
                        !isExpired && (
                            <Alert
                                type="info"
                                message={i18next.t('finishSigningInfo')}
                                action={{
                                    label: i18next.t('finishSigning'),
                                    onClick: () =>
                                        (window.location.href = signingPageUrl),
                                }}
                            />
                        )}
                    {valuation.contract?.pdfAvailable && isSigned && (
                        <>
                            <DownloadContract
                                label={i18next.t('downloadContract')}
                                icon="file_download"
                                showLabel="right"
                                onClick={() =>
                                    handleDownloadContract(
                                        valuation.valuationId.toString(),
                                    )
                                }
                            />
                            <Alert
                                type="success"
                                heading={i18next.t('contractSigned')}
                                message={i18next.t('paymentInfo')}
                            />
                        </>
                    )}
                    {isRejected && (
                        <Alert
                            type="error"
                            heading={i18next.t('declinedContractTitle')}
                            message={i18next.t('declinedContractMessage')}
                        />
                    )}
                </div>
            )}
        </>
    );
};
