import {
    BodyText,
    Modal,
    RadioButton,
    TextArea,
    AlertBanner,
} from '@gnist/design-system';
import { styled } from 'styled-components';
import i18next from 'i18next';
import { useState } from 'react';
import { LoadingOverlay } from '@/components/loading/LoadingOverlay';
import { useParams } from 'react-router';
import parse from 'html-react-parser';
import { PriceEstimateCustomerResponse } from '@/types/PriceEstimateModel';
import { useUpdatePriceEstimate } from '../../queries/useUpdatePriceEstimate';
import { usePurchaserEmail } from '@/utils/usePurchaserEmail';
import { tokens } from '@gnist/themes/tokens.css.js';

const Title = styled(BodyText)`
    font-size: ${tokens.typeface.size.l};
    font-weight: 500;
`;

const RadioButtonWrapper = styled.fieldset`
    border: 0;
    padding: 0;
    margin: 0;
    display: grid;
    row-gap: ${tokens.spacing.xs};
`;

const Description = styled.p`
    margin: ${tokens.spacing.xxs} 0 ${tokens.spacing.l};
`;

const Legend = styled.legend`
    margin-bottom: ${tokens.spacing.xs};
    font-weight: 500;
`;

const ErrorBanner = styled(AlertBanner)`
    margin-top: ${tokens.spacing.m};

    a {
        color: ${tokens.color['on-error-container']};
    }
`;

const CommentWrapper = styled.div`
    margin-top: ${tokens.spacing.s};

    > * {
        width: 100%;
    }
`;

const declinationResponses: PriceEstimateCustomerResponse[] = [
    PriceEstimateCustomerResponse.PRICE_TOO_LOW,
    PriceEstimateCustomerResponse.VALUE_EXPLORATION,
    PriceEstimateCustomerResponse.SELLING_TO_SOMEONE_ELSE,
    PriceEstimateCustomerResponse.OTHER,
];

interface DeclinationResponseModalProps {
    readonly close: () => void;
    readonly showNPSBanner: () => void;
    readonly isModalOpen: boolean;
}

export const DeclinationResponseModal = ({
    close,
    showNPSBanner,
    isModalOpen,
}: DeclinationResponseModalProps) => {
    const { id } = useParams<{ id: string }>();
    const { email } = usePurchaserEmail();
    const updatePriceEstimate = useUpdatePriceEstimate(id, () => {
        close();
        showNPSBanner();
    });
    const { isError, isLoading } = updatePriceEstimate;
    const [selectedResponse, setSelectedResponse] =
        useState<PriceEstimateCustomerResponse>(
            PriceEstimateCustomerResponse.PRICE_TOO_LOW,
        );
    const [responseComment, setResponseComment] = useState('');

    const handleComment = () => {
        if (selectedResponse === PriceEstimateCustomerResponse.OTHER) {
            return responseComment;
        }
        return undefined;
    };

    return (
        <Modal
            handleClose={close}
            isOpen={isModalOpen}
            primaryAction={{
                onClick: () =>
                    updatePriceEstimate.mutate({
                        customerResponse: selectedResponse,
                        customerResponseComment: handleComment(),
                    }),
                label: i18next.t('sendReason'),
            }}
            secondaryAction={{
                onClick: close,
                label: i18next.t('cancel'),
            }}
        >
            {isLoading && <LoadingOverlay delayMillis={0} />}
            <Title>{i18next.t('declinationTitle')}</Title>
            <Description>{i18next.t('declinationDescription')}</Description>
            <Legend>{i18next.t('declinationQuestion')}</Legend>
            <RadioButtonWrapper>
                {declinationResponses.map((response) => (
                    <div key={response}>
                        <RadioButton
                            key={response}
                            label={i18next.t(response)}
                            id={response}
                            name="rejectionReasonRadio"
                            value={response === selectedResponse}
                            onChange={() => setSelectedResponse(response)}
                        />
                    </div>
                ))}
            </RadioButtonWrapper>
            <CommentWrapper>
                <TextArea
                    id="responseComment"
                    label={i18next.t('explainWhy')}
                    value={responseComment}
                    onChange={(e) => setResponseComment(e.target.value)}
                    autoFocus
                />
            </CommentWrapper>
            {isError && (
                <ErrorBanner
                    message={
                        parse(
                            i18next.t('generalErrorMessage', {
                                email: email,
                            }),
                        ) as string
                    }
                    type="error"
                    density="compact"
                />
            )}
        </Modal>
    );
};
