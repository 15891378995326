import {
    matchesRegex,
    validation,
    ValidationStepResult,
} from '@gnist/design-system/utilities/validation';
import i18next from 'i18next';

const VALID_URL = new RegExp(
    '((http|https)://)(www.)?[a-zA-Z0-9@:%._+~#?&/=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._+~#?&/=]*)',
);

const SAFE_INPUT_CHARS = /^[a-zA-ZæøåÆØÅ0-9.,;:!?'"()[\]\-_/ ]*$/;

export const matchesUsedCarSalesUrls =
    () =>
    (value: string): ValidationStepResult => ({
        isValid:
            value.includes('finn.no') ||
            value.includes('bruktbil.no') ||
            value.includes('bruktbil.mollerbil.no'),
        errorMessage:
            'Url-en må være fra enten finn.no, bruktbil.no eller bruktbil.mollerbil.no',
    });

export const isValidUrl = (
    extraStep: (value: string) => ValidationStepResult,
) =>
    validation({
        steps: [
            matchesRegex(
                VALID_URL,
                'Url-en er ikke gyldig. Eksempel: http://www.example.com',
            ),
            extraStep,
        ],
    });

export const hasSafeInputCharacters = () =>
    validation({
        steps: [
            matchesRegex(SAFE_INPUT_CHARS, i18next.t('invalidCharactersUsed')),
        ],
    });
