import i18next from 'i18next';
import { DensitySizes } from '@gnist/design-system/foundation/typography';
import { styled } from 'styled-components';
import { AlertBanner } from '@gnist/design-system';
import parse from 'html-react-parser';
import { SHARED_PURCHASER_EMAIL } from '@/utils/emailUtils';
import { tokens } from '@gnist/themes/tokens.css.js';

export const Banner = styled(AlertBanner)`
    margin: ${tokens.spacing.m} 0 ${tokens.spacing.xl};

    a {
        color: ${tokens.color['on-background']};
        font-weight: 500;
    }
`;

export const FormErrorAlert = ({
    textKey = 'generalErrorMessage',
    className,
    density = 'compact',
}: {
    textKey?: string;
    className?: string;
    density?: DensitySizes;
}) => {
    return (
        <Banner
            className={className}
            type="error"
            density={density}
            message={
                parse(
                    i18next.t(textKey, {
                        email: SHARED_PURCHASER_EMAIL,
                    }),
                ) as string
            }
            dismiss={() => undefined}
        />
    );
};
