import { styled } from 'styled-components';
import { InfoCard } from '@gnist/design-system';
import i18next from 'i18next';
import { numberFormat } from '@/utils/numberFormat';
import {
    CardSubTitle,
    CardSubTitlePrefix,
    CardTitle,
} from './PriceEstimateContent';
import { Collapse } from '@/components/Collapse';
import { tokens } from '@gnist/themes/tokens.css.js';

const Card = styled(InfoCard)`
    background-color: ${tokens.color['surface-variant']};
    border-color: ${tokens.color['surface-variant']};
    margin-bottom: ${tokens.spacing.l};
    color: ${tokens.color.primary};
    padding: ${tokens.spacing.xs} ${tokens.spacing.l};
`;

export const MarketPriceCard = ({
    marketMinPrice,
    marketMaxPrice,
}: {
    marketMinPrice: number;
    marketMaxPrice: number;
}) => {
    return (
        <Card>
            <CardTitle>{i18next.t('marketPrice')}</CardTitle>
            <CardSubTitlePrefix>
                {i18next.t('similarCarsSoldFor')}
            </CardSubTitlePrefix>
            <CardSubTitle>{`${numberFormat(marketMinPrice)} - ${numberFormat(
                marketMaxPrice,
            )} kr`}</CardSubTitle>
            <Collapse
                id="market-price-card-content"
                backgroundColorToken={tokens.color['surface-variant']}
                collapsedHeight="0"
            >
                <p>{i18next.t('marketPriceInfo')}</p>
            </Collapse>
        </Card>
    );
};
