import { FormButtons } from './FormButtons';
import {
    SelfDeclarationElectricCarFormModel,
    SelfDeclarationFormModel,
} from '@/views/self-declaration/utils/selfDeclarationFormUtils';
import { SelfDeclarationForm } from '@/views/self-declaration/components/SelfDeclarationForm';
import { SelfDeclarationModel } from '@/types/SelfDeclarationModel';
import i18next from 'i18next';
import { styled } from 'styled-components';
import { tokens } from '@gnist/themes/tokens.css.js';

export const InfoText = styled.p`
    margin: calc(${tokens.size.l} * -1) 0 ${tokens.size.xl};
`;

interface SelfDeclarationStepProps {
    goToNextStep: () => void;
    goToPreviousStep: () => void;
    selfDeclaration?: SelfDeclarationModel;
    setSelfDeclaration: (selfDeclaration: SelfDeclarationModel) => void;
    isElectric: boolean;
}

export const SelfDeclarationStep = ({
    goToNextStep,
    goToPreviousStep,
    selfDeclaration,
    setSelfDeclaration,
    isElectric,
}: SelfDeclarationStepProps) => {
    const handleOnSubmit = (
        data: SelfDeclarationFormModel | SelfDeclarationElectricCarFormModel,
    ) => {
        setSelfDeclaration(data as SelfDeclarationModel);
        goToNextStep();
    };

    return (
        <>
            <InfoText>{i18next.t('selfDeclarationCardInfo')}</InfoText>
            <SelfDeclarationForm
                onSubmit={(data) => handleOnSubmit(data)}
                selfDeclaration={selfDeclaration}
                isElectric={isElectric}
                hideNecessityText
                formBottomContent={
                    <FormButtons
                        extraMargin
                        primaryAction={{
                            textKey: 'next',
                        }}
                        secondaryAction={{
                            textKey: 'previous',
                            onClick: () => goToPreviousStep(),
                        }}
                    />
                }
            />
        </>
    );
};
