import {
    required,
    ValidatorMap,
} from '@gnist/design-system/utilities/validation';
import { PriceOfferCustomerResponse } from '@/types/OfferModel';
import { hasSafeInputCharacters } from '@/utils/form/validation';

export interface DeclinationOfferResponseFormModel {
    selectedResponse: PriceOfferCustomerResponse;
    responseComment: string;
}

export const initialDeclinationOfferResponseFormValues: DeclinationOfferResponseFormModel =
    {
        selectedResponse: PriceOfferCustomerResponse.DECLINED_PRICE_TOO_LOW,
        responseComment: '',
    };

export const declinationOfferResponseFormValidator =
    (): ValidatorMap<DeclinationOfferResponseFormModel> => ({
        selectedResponse: required(),
        responseComment: hasSafeInputCharacters(),
    });
