import { CompanyCarForm } from './CompanyCarForm';
import i18next from 'i18next';
import { styled } from 'styled-components';
import { ValuationType } from '@/utils/enums/ValuationType';
import { DealerModel } from '@/types/DealerModel';
import { AlertMessage } from './AlertMessage';
import { useEffect } from 'react';
import { datadogRum } from '@datadog/browser-rum';
import { ValuationForm } from './valuation-form/ValuationForm';
import { TradeInForm } from './TradeInForm';
import { RequestModel } from '../queries/useConfirmRequest';
import { useGetUrlParams } from '../utils/useGetUrlParams';
import { Heading1Text } from '@gnist/design-system';
import { tokens } from '@gnist/themes/tokens.css.js';

interface FormPageContentProps {
    readonly valuationType: ValuationType;
    readonly dealers: DealerModel[] | undefined;
    readonly handleOnSubmit: (
        data: RequestModel,
        selectedDealer?: DealerModel,
        authToken?: string,
    ) => void;
    readonly pageTitle: string;
    readonly isError: boolean;
    readonly setCarModel: (carModel: string) => void;
    readonly setUserId: (userId: string) => void;
}

const Description = styled.div`
    margin: ${tokens.spacing.xxs} 0 ${tokens.spacing.xl};
`;

const ErrorMessage = styled.div`
    background-color: ${tokens.color['error-container']};
    margin: ${tokens.spacing.s} 0;
    padding: ${tokens.spacing.s};
    display: flex;

    p {
        color: ${tokens.color.error};
    }
`;

export const FormPageContent = ({
    valuationType,
    dealers,
    handleOnSubmit,
    pageTitle,
    isError,
    setCarModel,
    setUserId,
}: FormPageContentProps) => {
    const { srcFromUrl } = useGetUrlParams();

    useEffect(() => {
        if (srcFromUrl === 'bilhold') {
            datadogRum.addAction('Arrived from bilhold to valuation form');
        }
        if (srcFromUrl === 'bilholdPriceEstimate') {
            datadogRum.addAction(
                'Arrived from bilhold price estimate to valuation form',
            );
        }
        if (srcFromUrl === 'booking') {
            datadogRum.addAction('Arrived from booking to valuation form');
        }
        if (srcFromUrl === 'edm') {
            datadogRum.addAction('Arrived from edm to valuation form');
        }
        if (srcFromUrl === 'edmApe') {
            datadogRum.addAction(
                'Arrived from edm with price estimate to valuation form',
            );
        }
    }, [srcFromUrl]);

    return (
        <>
            {valuationType === ValuationType.CONSUMER && (
                <ValuationForm
                    dealers={dealers}
                    handleOnSubmit={handleOnSubmit}
                    isError={isError}
                    setCarModel={setCarModel}
                    setUserId={setUserId}
                />
            )}
            {valuationType === ValuationType.COMPANY_CAR && (
                <>
                    <Heading1Text>{pageTitle}</Heading1Text>
                    <AlertMessage />
                    <CompanyCarForm
                        dealers={dealers}
                        handleOnSubmit={handleOnSubmit}
                    />
                </>
            )}
            {valuationType === ValuationType.TRADE_IN && (
                <>
                    <Heading1Text>{pageTitle}</Heading1Text>
                    <Description>{i18next.t('tradeInInfo')}</Description>
                    <AlertMessage />
                    <TradeInForm
                        dealers={dealers}
                        handleOnSubmit={handleOnSubmit}
                    />
                </>
            )}
            {(valuationType === ValuationType.COMPANY_CAR ||
                valuationType === ValuationType.TRADE_IN) &&
                isError && (
                    <ErrorMessage>
                        <p>{i18next.t('requestFailedError')}</p>
                    </ErrorMessage>
                )}
        </>
    );
};
